import React, {Component} from 'react';
import IntervalTimer from "react-interval-timer";
import moment from "moment";

class TimeView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            day: moment().format('YYYY年M月D日 ddd'),
            time: moment().format('H:mm')
        }
    }

    componentWillReceiveProps(nextProps){
    }

    componentWillUnmount(){
    }

    getTime = () => {
        this.setState({
            day: moment().format('YYYY年M月D日 ddd'),
            time: moment().format('H:mm')
        })
    };

    render() {
        return (
            <div id="Menu_time">
                <div id="Menu_time1">{this.state.day}</div>
                <div id="Menu_time2">{this.state.time}</div>
                <IntervalTimer
                    enabled={true}
                    callback = {this.getTime}
                    timeout={1000}
                />
            </div>
        )
    }
}

export default TimeView