import React, {Component} from 'react';
import moment from 'moment'
import "moment/locale/ja";
import "scss/Menu.scss"
import {Motion, spring} from 'react-motion';
import MenuInfinity from "images/menu_event.png"
import MenuEvent from "images/menu_event.png"
import MenuNews from "images/menu_news.png"
import MenuStudentsNews from "images/menu_students_news.png"
import MenuYoutube from "images/menu_youtube.png"
import MenuTwitter from "images/menu_twitter.png"
import MenuFacebook from "images/menu_facebook.png"

import * as url from 'constants/api'

import image from "images/header_logo.png"

import QRCode from 'react-qr-component';


class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            day: moment().format('YYYY年M月D日 ddd'),
            time: moment().format('H:mm'),
            currentMenuNum : 1
        }
    }

    componentDidMount(){
        this.setTimerInterval();
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            currentMenuNum: nextProps.currentMenuNum
        })
    }

    setTimerInterval() {
        clearInterval(this.state.timeIntervalTimer)
        const constTimeIntervalTimer = setInterval(() => {
            this.setState({
                day: moment().format('YYYY年M月D日 ddd'),
                time: moment().format('H:mm')
            })
        }, 1000);
        this.setState({
            timeIntervalTimer: constTimeIntervalTimer
        })
    }

    changeMenu = (num) =>{
        this.setState({
            currentMenuNum: num
        });
        this.props.changeMenu(num)
    };

    render() {
        return (
            <div className="App-right-bar">
                <div id="Menu_time">
                    <div id="Menu_time1">{this.state.day}</div>
                    <div id="Menu_time2">{this.state.time}</div>
                </div>
                <div id="Menu_outer">
                    <Motion style={{x: spring(this.state.currentMenuNum === 0 ? 0 : 40)}}>
                        {({x}) =>
                            // children is a callback which should accept the current value of
                            // `style`
                            <div
                                id="Tab_konan_infinity" className="Menu_tab"
                                style={{
                                    transform: `translate3d(${x}px, 0, 0)`,
                                    WebkitTransform: `translate3d(${x}px, 0, 0)`,
                                }}
                                onClick={(e) => this.changeMenu(0)}
                            >
                                <img className="Menu_icon" alt="infinity" src={MenuInfinity}/>
                                <div className="Menu_title">施設利用状況</div>
                            </div>
                        }
                    </Motion>
                    <Motion style={{x: spring(this.state.currentMenuNum === 1 ? 0 : 40)}}>
                        {({x}) =>

                            // children is a callback which should accept the current value of
                            // `style`
                            //移動距離 {x} px
                            <div id="Tab_facebook" className="Menu_tab"
                                 style={{
                                     transform: `translate3d(${x}px, 0, 0)`,
                                     WebkitTransform: `translate3d(${x}px, 0, 0)`,
                                 }}
                                 onClick={() => this.changeMenu(1)}
                            >
                                <img className="Menu_icon" alt="MenuStudentNews" src={MenuStudentsNews}/>
                                <div className="Menu_title">他プロダクト紹介</div>
                            </div>
                        }
                    </Motion>
                    {/*
                    <Motion style={{x: spring(this.state.currentMenuNum === 2 ? 0 : 40)}}>
                        {({x}) =>
                            // children is a callback which should accept the current value of
                            // `style`

                            <div id="Tab_user_news" className="Menu_tab"
                                 style={{
                                     transform: `translate3d(${x}px, 0, 0)`,
                                     WebkitTransform: `translate3d(${x}px, 0, 0)`,
                                 }}
                                 onClick={() => this.changeMenu(2)}
                            >
                                <img className="Menu_icon" alt="MenuStudentNews" src={MenuStudentsNews}/>
                                <div className="Menu_title">投稿News</div>
                            </div>
                        }
                    </Motion>

                    <Motion style={{x: spring(this.state.currentMenuNum === 3 ? 0 : 40)}}>
                        {({x}) =>
                            // children is a callback which should accept the current value of
                            // `style`

                            <div id="Tab_university_news" className="Menu_tab"
                                 style={{
                                     transform: `translate3d(${x}px, 0, 0)`,
                                     WebkitTransform: `translate3d(${x}px, 0, 0)`,
                                 }}
                                 onClick={() => this.changeMenu(3)}
                            >
                                <img className="Menu_icon" alt="news" src={MenuNews}/>
                                <div className="Menu_title">News</div>
                            </div>
                        }
                    </Motion>

                    <Motion style={{x: spring(this.state.currentMenuNum === 4 ? 0 : 40)}}>
                        {({x}) =>
                            // children is a callback which should accept the current value of
                            // `style`

                            <div id="Tab_facebook" className="Menu_tab"
                                 style={{
                                     transform: `translate3d(${x}px, 0, 0)`,
                                     WebkitTransform: `translate3d(${x}px, 0, 0)`,
                                 }}
                                 onClick={() => this.changeMenu(4)}
                            >
                                <img className="Menu_icon" alt="facebook" src={MenuFacebook}/>
                                <div className="Menu_title">Facebook</div>
                            </div>
                        }
                    </Motion>
                    <Motion style={{x: spring(this.state.currentMenuNum === 5 ? 0 : 40)}}>
                        {({x}) =>
                            // children is a callback which should accept the current value of
                            // `style`
                            //移動距離 {x} px
                            <div id="Tab_twitter" className="Menu_tab"
                                 style={{
                                     transform: `translate3d(${x}px, 0, 0)`,
                                     WebkitTransform: `translate3d(${x}px, 0, 0)`,
                                 }}
                                 onClick={() => this.changeMenu(5)}
                            >

                                <img className="Menu_icon" alt="twitter" src={MenuTwitter}/>
                                <div className="Menu_title">Twitter</div>
                            </div>
                        }
                    </Motion>

                    <Motion style={{x: spring(this.state.currentMenuNum === 6 ? 0 : 40)}}>
                        {({x}) =>
                            // children is a callback which should accept the current value of
                            // `style`

                            <div id="Tab_youtube" className="Menu_tab"
                                 style={{
                                     transform: `translate3d(${x}px, 0, 0)`,
                                     WebkitTransform: `translate3d(${x}px, 0, 0)`,
                                 }}
                                 onClick={() => this.changeMenu(6)}
                            >
                                <img className="Menu_icon" alt="youtube" src={MenuYoutube}/>
                                <div className="Menu_title">公式YouTube</div>
                            </div>
                        }
                    </Motion>
                    */}
                </div>

                {/*
                <div className="qr_box">
                    <div className="qr_code_box">
                        <div className="qr_code_left">
                            <h4>記事を投稿してみよう！</h4>
                            <p>QRコードを読み込み記事を投稿してみよう。あなたの記事がWallに載るかも？このデジタルサイネージや投稿に関することはSibaServiceにお問い合わせください。</p>
                        </div>
                        <div className="qr_code_right">
                            <QRCode value="http://siba-service.jp/" fgColor={"#500000"} bgColor={"#FFFFFF"} size={80} level={"L"}/>
                        </div>
                    </div>
                    <div className="qr_code_uri">http://siba-service.jp/</div>
                </div>
                */}
            </div>
        );
    }
}

export default Menu;
