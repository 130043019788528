/**
 * Created by sibaservice on 2017/05/15.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import "moment/locale/ja";
import "../scss/Grid.scss"
import log from '../utils/logger';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import IconButton from 'material-ui/IconButton';
import ImageGallery from "react-image-gallery";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Dotdotdot from 'react-dotdotdot'
import LikeButton from "./LikeButton"
import {Textfit} from 'react-textfit';
import ReactFitText from 'react-fittext'
import * as Constants from '../constants/constants'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import ClassNames from 'classnames';
import "../scss/animation.scss"
import {getClassNames} from "../constants/constants";

export default class CardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            card_id: props.card_id,
            isButtonAnimation: false,
            data: props.data,
            cxCardOuter : ClassNames({
                "disable_card": props.data === Constants.DATA_PAYLOAD,
                "card_outer": true,
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.data !== this.state.data ){
            this.setState({
                data: nextProps.data,
                cxCardOuter : ClassNames({
                    "disable_card": nextProps.data === Constants.DATA_PAYLOAD,
                    "card_outer": true,
                })
            })

        }
    }



    handleTouch = () => {
        this.props.showDetail(this.state.data);
    }

    handleLike = () => {

        this.setState({
            isButtonAnimating: true
        })
    }

    render() {

        let thumb_style = {}
        let sub_title_style = {}

        let card_thumb_outer_style = {}
        let card_description_style = {}
        let card_title_outer_style = {}
        let icon_style = {}
        let card_text_outer_style = {
            flexGrow:1
        }
        let card_text_style = {}


        let imageArray = this.state.data.MEDIAS;
        let onlyString = imageArray.length < 1;
        let clampValue = "auto";


        switch (this.state.card_id) {
            case "card_w4h7":
                if (!onlyString) {
                    thumb_style = {
                        width: "100%",
                        height: this.props.screenSize.screenHeight / 12 * 6 - 10,
                    }
                    card_description_style = {
                        position: "absolute",
                        bottom: 0,
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        backgroundColor: "RGBA(255,255,255,0.7)",
                        height: "70px",
                    }
                    card_title_outer_style = {
                        paddingBottom: 5
                    }
                    clampValue = 11;
                } else {
                    thumb_style = {
                        width: "100%",
                        height: 0,
                        display: "none",
                    }
                    card_description_style = {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: (this.props.screenSize.screenHeight / 12 * 6 - 10),
                    }
                    card_title_outer_style = {
                        paddingBottom: 5
                    }
                    clampValue = 19;
                }
                break;

            case "card_w4h5":
                if (!onlyString) {
                    thumb_style = {
                        width: "100%",
                        height: this.props.screenSize.screenHeight / 12 * 5 - 10 - (this.props.screenSize.screenHeight / 12 * 5 * 0.38),
                    }
                    card_description_style = {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: (this.props.screenSize.screenHeight / 12 * 5 * 0.38),
                    }
                    card_title_outer_style = {
                        paddingBottom: 5
                    }
                    icon_style = {
                        top: this.props.screenSize.screenHeight / 12 * 5 - 10 - (this.props.screenSize.screenHeight / 12 * 5 * 0.38) - 20,
                        backgroundColor: "#cccccc",
                        zIndex: "2",
                        left: "10px",
                        height: "80px",
                        width: "80px"
                    }
                } else {
                    thumb_style = {
                        width: "100%",
                        height: 0,
                        position: "none",
                    }
                    card_description_style = {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: (this.props.screenSize.screenHeight / 12 * 5 - 10),
                    }
                    card_title_outer_style = {
                        paddingBottom: 5
                    }
                }
                break;
            case "card_w4h4":
                if (!onlyString) {
                    thumb_style = {
                        width: "100%",
                        height: this.props.screenSize.screenHeight / 12 * 4 - 10 - (this.props.screenSize.screenHeight / 12 * 4 * 0.38),
                    }
                    card_description_style = {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: (this.props.screenSize.screenHeight / 12 * 4 * 0.38),
                    }
                    card_title_outer_style = {
                        paddingBottom: 5
                    }
                    icon_style = {
                        top: this.props.screenSize.screenHeight / 12 * 4 - 10 - (this.props.screenSize.screenHeight / 12 * 4 * 0.38) - 20,
                        backgroundColor: "#cccccc",
                        zIndex: "2",
                        left: "10px",
                        height: "80px",
                        width: "80px"
                    }
                } else {
                    thumb_style = {
                        width: "100%",
                        height: 0,
                        display: "none",
                    }
                    card_description_style = {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: (this.props.screenSize.screenHeight / 12 * 4 - 10),
                    }
                    card_title_outer_style = {
                        paddingBottom: 5
                    }
                }
                break;

            case "card_w4h3":
                if (!onlyString) {
                    thumb_style = {
                        width: "100%",
                        height: this.props.screenSize.screenHeight / 12 * 3 - 10,
                    }
                    card_description_style = {
                        position: "absolute",
                        bottom: 0,
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        backgroundColor: "RGBA(255,255,255,0.7)",
                        height: "70px",
                    }
                    card_title_outer_style = {
                        paddingBottom: 5
                    }
                    clampValue = 16;
                } else {
                    thumb_style = {
                        width: (this.props.screenSize.screenWidth / 12 * 4 - 10) * 0.38,
                        height: 0,
                        display: "none",
                    }
                    card_thumb_outer_style = {
                        height: 0,
                        display: "none",
                        width: (this.props.screenSize.screenWidth / 12 * 4 - 10) * 0.38,
                    }
                    card_description_style = {
                        position: "absolute",
                        top: 0,
                        right: 0,
                        display: "flex",
                        flexDirection: "column",
                        width: (this.props.screenSize.screenWidth / 12 * 4 - 10),
                        height: this.props.screenSize.screenHeight / 12 * 3 - 10,
                    }
                    card_title_outer_style = {
                        paddingBottom: 5
                    }
                    clampValue = 16;
                }

                break;


            case "card_w3h5":
                if (!onlyString) {
                    thumb_style = {
                        width: "100%",
                        height: this.props.screenSize.screenHeight / 12 * 5 - 10 - (this.props.screenSize.screenHeight / 12 * 5 * 0.38),
                    }
                    card_description_style = {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: (this.props.screenSize.screenHeight / 12 * 5 * 0.38),
                    }
                    card_text_style = {
                        display: "none",
                    }
                    card_title_outer_style = {
                        paddingBottom: 5
                    }
                } else {
                    thumb_style = {
                        width: "100%",
                        height: 0,
                        display: "none",
                    }
                    card_description_style = {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: (this.props.screenSize.screenHeight / 12 * 5 - 10),
                    }
                    card_title_outer_style = {
                        paddingBottom: 5
                    }
                }
                break;

            case "card_w3h4":
                if (!onlyString) {
                    thumb_style = {
                        width: "100%",
                        height: this.props.screenSize.screenHeight / 12 * 3 - 10,
                    }
                    card_description_style = {
                        position: "absolute",
                        bottom: 0,
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        backgroundColor: "RGBA(255,255,255,0.7)",
                        height: "70px",
                    }
                    card_title_outer_style = {}
                    card_text_style = {
                        display: "none",
                    }
                    icon_style = {
                        top: "10px",
                        backgroundColor: "#c22222",
                        zIndex: "2",
                        left: "10px",
                        height: "40px",
                        width: "40px"
                    }
                    clampValue = 3;
                } else {
                    thumb_style = {
                        width: "100%",
                        height: 0,
                        display: "none",
                    }
                    card_description_style = {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: (this.props.screenSize.screenHeight / 12 * 3 - 10),
                    }
                    card_title_outer_style = {}
                    icon_style = {
                        top: "10px",
                        backgroundColor: "#c22222",
                        zIndex: "2",
                        left: "10px",
                        height: "40px",
                        width: "40px"
                    }
                    clampValue = 16;
                }
                break;

            case "card_w3h3":

                if (!onlyString) {
                    thumb_style = {
                        width: "100%",
                        height: this.props.screenSize.screenHeight / 12 * 3 - 10,
                    }
                    card_description_style = {
                        position: "absolute",
                        bottom: 0,
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        backgroundColor: "RGBA(255,255,255,0.7)",
                        height: "70px",
                    }
                    card_title_outer_style = {
                        paddingBottom: 5
                    }
                    card_text_style = {
                        display: "none",
                    }
                } else {

                    thumb_style = {
                        width: "100%",
                        height: 0,
                        display: "none"
                    }
                    card_description_style = {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: (this.props.screenSize.screenHeight / 12 * 3 - 10),
                    }
                    clampValue = 16;
                }
                break;

            case "card_w2h3":

                if (!onlyString) {
                    thumb_style = {
                        width: "100%",
                        height: this.props.screenSize.screenHeight / 12 * 3 - 10,
                    }
                    card_description_style = {
                        position: "absolute",
                        bottom: 0,
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        backgroundColor: "RGBA(255,255,255,0.4)",
                        height: (this.props.screenSize.screenHeight / 12 * 3 * 0.34),
                    }
                    card_title_outer_style = {
                        paddingBottom: 5
                    }
                    icon_style = {
                        top: this.props.screenSize.screenHeight / 12 * 3 - 10 - (this.props.screenSize.screenHeight / 12 * 3 * 0.34) - 20,
                        zIndex: "2",
                        left: "10px",
                        height: "80px",
                        width: "80px"
                    }
                    card_text_outer_style = {
                        height: "0",
                        display: "none"
                    }
                    card_text_style = {
                        display: "none"
                    }
                } else {

                    thumb_style = {
                        width: "100%",
                        height: 0,
                        display: "none"
                    }
                    card_description_style = {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: (this.props.screenSize.screenHeight / 12 * 3 - 10),
                    }
                    icon_style = {
                        top: this.props.screenSize.screenHeight / 12 * 3 - 10 - 20,
                        backgroundColor: "#cccccc",
                        zIndex: "2",
                        left: "10px",
                        height: "80px",
                        width: "80px"
                    }
                    clampValue = 3;

                }
                break;


            case "card_w2h2":

                if (!onlyString) {
                    thumb_style = {
                        width: "100%",
                        height: this.props.screenSize.screenHeight / 12 * 3 - 10,
                    }
                    card_description_style = {
                        position: "absolute",
                        bottom: 0,
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        backgroundColor: "RGBA(255,255,255,0.7)",
                        height: "50px",
                    }
                    card_title_outer_style = {
                        paddingBottom: 5
                    }
                    icon_style = {
                        top: this.props.screenSize.screenHeight / 12 * 3 - 10 - (this.props.screenSize.screenHeight / 12 * 3 * 0.24) - 20,
                        zIndex: "2",
                        left: "10px",
                        height: "80px",
                        width: "80px"
                    }
                    card_text_outer_style = {
                        height: "0",
                        display: "none"
                    }
                    card_text_style = {
                        display: "none"
                    }

                } else {

                    thumb_style = {
                        width: "100%",
                        height: 0,
                        display: "none"
                    }
                    card_description_style = {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: (this.props.screenSize.screenHeight / 12 * 3 - 10),
                    }
                    icon_style = {
                        top: this.props.screenSize.screenHeight / 12 * 3 - 10 - 20,
                        backgroundColor: "#cccccc",
                        zIndex: "2",
                        left: "10px",
                        height: "80px",
                        width: "80px"
                    }
                    clampValue = 16;

                }
                break;


            default:
                thumb_style = {
                    width: (this.props.screenSize.screenWidth) / 12 * 2 - 10,
                    height: this.props.screenSize.screenHeight / 9 - 10,
                    textAlign: "left"
                }
                break;
        }


        //カテゴリ用アイコン制御部
        let iconClassName = getClassNames(this.state.data.CATEGORY_ID,true);

        let component;
        if (this.state.card_id === "card1") {
            component = (
                <Card className="card_outer">
                    <div className="Notice_qrcode"
                         style={thumb_style}>
                        <p style={{margin: "0px", fontSize: "18px", padding: "10px 10px 5px 10px"}}>記事を投稿してみよう</p>
                        <p style={{margin: "0px", fontSize: "12px", padding: "5px 10px 10px 10px"}}>
                            {/*
                            //todo 後で戻す
                            右記のQRコードを読み込み記事を投稿してみよう。あなたの記事がWallに載るかも？
                            */}
                        </p>
                    </div>
                </Card>
            )
        } else {


            let settings;
            //todo 複数枚数のときと、単数枚数のときでのSlider制御文の追加


            let thumbBody;
            if (!onlyString) {

                let filteredItems = imageArray.filter((value, index) => {
                    if(value.TYPE === "image" || value.TYPE === "thumbnail"){
                        return true;
                    }
                })
                //サムネ部分の処理の追加　// youtubeの場合はサムネ一枚確定のためswipeさせない
                if (filteredItems.length === 1 ) {
                    //todo 切替
                    let reFormatUrl = Constants.ReformatImageStore(filteredItems[0].URL,thumb_style.height,0);
                    //let reFormatUrl = filteredItems[0].URL;
                    let style = {
                        backgroundImage: "url('" + reFormatUrl + "')",
                        backgroundPosition: "center",
                        backgroundSize: "cover"
                    };
                    thumbBody = (
                        <div>
                            <div style={{ ...thumb_style,...style}}/>
                        </div>
                    )
                } else {
                    settings = {
                        dots: false,
                        arrows: false,
                        adaptiveHeight: true,
                        autoplay: true,
                        infinite: true,
                        speed: 2000,
                        autoplaySpeed: 7000,
                        touchMove: false,
                        swipe: false,
                        swipeToSlide: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    };
                    let listItems = filteredItems.map((value, index) => {
                        log.debug("slider bug",value);
                            if(value.TYPE === "image" || value.TYPE === "thumbnail"){
                                let reFormatUrl = Constants.ReformatImageStore(value.URL,thumb_style.height,0);
                                let style = {
                                    backgroundImage: "url('" + reFormatUrl + "')",
                                    backgroundPosition: "center",
                                    backgroundSize: "cover"
                                };
                                return (
                                    <div className={"fuck"}>
                                        <div  style={{...thumb_style,...style}} />
                                    </div>
                                )
                            }
                        }
                    );
                    thumbBody = (
                        <div style={{...thumb_style}}>
                            <Slider {...settings} >
                                {listItems}
                            </Slider>
                        </div>
                    )
                }
            } else {

            }
            component = (
                <Card className={this.state.cxCardOuter}>
                    <ReactCSSTransitionGroup
                        transitionName="grid"
                        transitionAppear={true}
                        transitionAppearTimeout={500}
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={300}>
                        <div className="cardThumbOuter" style={card_thumb_outer_style} onClick={this.handleTouch}>
                            {thumbBody}
                        </div>

                        <div style={card_description_style} className="cardDescriptionOuter">
                            <div className="cardTitleOuter" style={card_title_outer_style}>
                                <div
                                    className="cardTitle"  onClick={this.handleTouch} >
                                <span>
                                    {this.state.data.TOPIC_TITLE.replace(/甲南/g,"ナレッジ")}
                                </span>
                                    <span>
                                    {this.state.data.TOPIC_AUTHOR_NAME.replace(/甲南/g,"ナレッジ")}
                                </span>
                                </div>
                                <div onClick={this.handleTouch}
                                     className={iconClassName}
                                />
                                <LikeButton topicId={this.state.data.TOPIC_ID} voteCount={this.state.data.VOTES.WALL_LIKES}/>
                            </div>

                            <div onClick={this.handleTouch} style={card_text_outer_style}>
                                <div className="cardText" style={card_text_style}>
                                    <Dotdotdot clamp={clampValue}>

                                            {this.state.data.TOPIC_BODY.replace(/\\n/g, '\r\n').replace(/甲南/g,"ナレッジ")}
                                    </Dotdotdot>
                                </div>
                            </div>
                        </div>
                    </ReactCSSTransitionGroup>
                </Card>
            )
        }

        return component;
    }
}

CardComponent.propTypes = {
    data: PropTypes.object
}
