import React, {Component} from 'react'
import ERROR_OUTLINE from 'material-ui/svg-icons/alert/error-outline';
import RaisedButton from 'material-ui/RaisedButton';

import * as url from 'constants/api'
import QRCode from 'react-qr-component';
import Slider from "react-slick";
import "scss/custom-slick-theme.scss"

class Demo extends Component {
    render() {
        const settings = {
            arrows: false,
            dots: true,
            infinite: true,
            touchMove: true,
            focusOnSelect: false,
            className: "demo-slide",
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 10000,
            autoplay: true,
            beforeChange: function (currentSlide, nextSlide) {
                console.log("before change", currentSlide, nextSlide);
            },
            afterChange: function (currentSlide) {
                console.log("after change", currentSlide);
            }
        };

        return (
            <div className="App-left-component" id="advertisement">
                <Slider {...settings}>
                    <div >
                        <img style={{width: "100%"}} src={process.env.PUBLIC_URL + '/png/signage04.png'}/>
                    </div>
                    <div>
                        <img style={{width: "100%"}} src={process.env.PUBLIC_URL + '/png/signage03.png'}/>
                    </div>
                    <div>
                        <img style={{width: "100%"}} src={process.env.PUBLIC_URL + '/png/signage02.png'}/></div>
                    <div>
                        <img style={{width: "100%"}} src={process.env.PUBLIC_URL + '/png/signage01.png'}/>
                    </div>
                </Slider>
            </div>
        )
    }
}

export default Demo