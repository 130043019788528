import React, {useRef, useLayoutEffect, useState, useEffect} from "react";
import PropTypes from 'prop-types';
import {Card} from "material-ui/Card";
import LikeButton from "./LikeButton";
import Dotdotdot from "react-dotdotdot";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import * as Constants from "../constants/constants";
import log from "../utils/logger";
import Slider from "react-slick";
import ClassNames from "classnames";
import {getClassNames} from "../constants/constants";

CardArticle.propTypes = {};

function CardArticle(props) {
    const [data, setData] = useState(props.data)
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({width: 0, height: 0});
    const [ready, setReady] = useState(false);

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    }, []);


    useEffect(() => {
    }, [dimensions])


    const handleTouch = () => {
        props.showDetail(data);
    }

    //カテゴリ用アイコン制御部
    let iconClassName = getClassNames(data.CATEGORY_ID,true);

    const getThumbBody = () => {
        let imageArray = data.MEDIAS;
        let onlyString = imageArray.length < 1;

        let thumbBody;
        if (!onlyString) {
            let filteredItems = imageArray.filter((value, index) => {
                if (value.TYPE === "image" || value.TYPE === "thumbnail") {
                    return true;
                }
            })
            //サムネ部分の処理の追加　// youtubeの場合はサムネ一枚確定のためswipeさせない
            if (filteredItems.length === 1) {
                let reFormatUrl = Constants.ReformatImageStore(filteredItems[0].URL, dimensions.height, dimensions.width);
                //let reFormatUrl = filteredItems[0].URL;
                let style = {
                    backgroundImage: "url('" + reFormatUrl + "')",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    width: dimensions.width,
                    height: dimensions.height
                };
                thumbBody = (
                    <div className={"thumb_body"} style={{...style}}/>
                )
            } else {
                const settings = {
                    dots: false,
                    arrows: false,
                    adaptiveHeight: true,
                    autoplay: true,
                    infinite: true,
                    speed: 2000,
                    autoplaySpeed: 7000,
                    touchMove: false,
                    swipe: false,
                    swipeToSlide: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                };
                const thumb_style = {
                    width: dimensions.width,
                    height: dimensions.height,
                }
                let listItems = filteredItems.map((value, index) => {
                    if (value.TYPE === "image" || value.TYPE === "thumbnail") {
                        let reFormatUrl = Constants.ReformatImageStore(value.URL, dimensions.height, 0);
                        let style = {
                            backgroundImage: "url('" + reFormatUrl + "')",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            width: dimensions.width,
                            height: dimensions.height
                        };
                        return (
                            <div>
                                <div key={index} style={{...style}}/>
                            </div>
                        )
                    }
                });
                thumbBody = (
                    <div style={{...thumb_style}}>
                        <Slider {...settings} >
                            {listItems}
                        </Slider>
                    </div>
                )
            }
        } else {
            thumbBody = (
                <div onClick={handleTouch}>
                    <div className="cardTextBody" style={{width: dimensions.width, height: dimensions.height}}>
                        {data.TOPIC_BODY.replace(/\\n/g, '\r\n')}
                    </div>
                </div>
            )

        }

        return thumbBody;
    }


    return (
        <div ref={targetRef} className={"cardArticleOuter"}>
            <ReactCSSTransitionGroup
                component="div" className={"cardTransitionComponent"}
                transitionName="grid"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}>
                <div className="cardThumbOuter" onClick={() => handleTouch()}>
                    {dimensions.height > 0 && getThumbBody()}
                </div>

                <div className="cardDescriptionOuter">
                    <div className="cardTitleOuter">
                        <div onClick={() => handleTouch()}
                             className={iconClassName}
                        />
                        <div className="cardTitle" onClick={() => handleTouch()}>
                            <span>
                                {data.TOPIC_TITLE.replace(/甲南/g, "ナレッジ")}
                            </span>
                            <span>
                                {data.TOPIC_AUTHOR_NAME.replace(/甲南/g, "ナレッジ")}
                            </span>
                        </div>
                        <div className={"cardLikeButton"}>
                            <LikeButton topicId={data.TOPIC_ID} voteCount={data.VOTES.WALL_LIKES}/>
                        </div>
                    </div>
                </div>
            </ReactCSSTransitionGroup>
        </div>
    );
}

export default CardArticle;
