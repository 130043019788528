/**
 * Created by sibaservice on 2017/05/15.
 */
import * as Actions from '../actions/actions'
import log from '../utils/logger';
import { handleActions,handleAction } from 'redux-actions';



const connectDefaultState = {
    payload:{
        data: {}
    },
    fetch : false
}
export const topics = handleActions({
    "TOPICS/REQUEST": (state, action) => ({
        ...action,
    }),
    "TOPICS/RECEIVE": (state, action) => ({
        ...action
    })
}, connectDefaultState);

export const vote = handleActions({
    "VOTE/REQUEST": (state, action) => ({
        ...action
    }),
    "VOTE/RECEIVE": (state, action) => ({
        ...action
    }),
},connectDefaultState)


export const status = handleActions({
    "STATUS/REQUEST": (state, action) => ({
        ...action
    }),
    "STATUS/RECEIVE": (state, action) => ({
        ...action
    }),
},connectDefaultState)



const loadingDefaultState={
    payload:false
}
export const loading = handleAction('LOADING', (state, action) => ({
    ...action
}), loadingDefaultState);


const initialStateScreenSize ={
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight
};

export function screenSize(state = initialStateScreenSize,action = null){
    switch(action.type){
        case  Actions.TYPE_UPDATE_SCREEN_SIZE:
            let tmpHeight;
            if(action.screenHeight < 1080 ){
                tmpHeight = 1080;
            }else{
                tmpHeight = action.screenHeight;
            }
            let tmpWidth;
            if(action.screenWidth < 1920){
                tmpWidth = 1920;
            }else{
                tmpWidth = action.screenWidth;
            }

            log.debug(tmpHeight)
            log.debug(tmpWidth)
            log.debug(tmpWidth * 0.9 - 48)
            return Object.assign({},
                state,
                {
                    screenHeight: tmpHeight,
                    screenWidth: tmpWidth,
                    //Dialogクラスの影20px 上パディング-24px 下パディング-24px 間パディング-24px タイトル-80px
                    dialogDetailBodyHeight: tmpHeight * 0.9 -20 -24 -24 -24 -80,
                    //Dialogの横幅に対しての左右パディング -24px*2
                    dialogDetailBodyWidth: tmpWidth * 0.9 - 48
                });
            break;
        default:
            return state
    }
}
