/**
 * Created by sibaservice on 2017/05/11.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect, store} from 'react-redux'
import * as Actions from '../actions/actions'
import moment from 'moment'
import "moment/locale/ja";
import log from '../utils/logger';
import {Card} from 'material-ui/Card';
import ReactSVG from 'react-svg'
import ClassNames from 'classnames';
import "../scss/map.scss"

import IntervalTimer from 'components/utils/IntervalTimer'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6

const mapStateToProps = (state, props) => {
    log.debug(state.screenSize)
    return {
        screenSize: state.screenSize,
        status: state.status
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getStatus: () => {
            dispatch(Actions.Connection.status.request("get"))
        }
    }
};

class MapOnly extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: props.screenSize.screenHeight,
            width: props.screenSize.screenWidth,
            currentFloor: 1,
            floorPath: process.env.PUBLIC_URL + '/svg/icommons-map-1f.svg',
            seats_status: props.seats_status,
            isFetching: true
        }
        props.getStatus();
    }


    componentWillReceiveProps(nextProps) {

        if (!nextProps.status.meta.fetch) {
            if (!nextProps.status.error) {
                if (nextProps.status.payload !== null) {
                    this.setState({
                        isFetching: nextProps.status.meta.fetch
                    })
                    log.debug(nextProps.status.payload);

                    //equipments_status
                    //seats_status
                    this.setState({
                        seats_status: nextProps.status.payload.seats_status
                    })
                }
            }
        } else {
            this.setState({
                isFetching: nextProps.status.meta.fetch
            })
        }

    }

    handleSvgLoaded = () => {
        let reserveItems = document.getElementsByClassName("reserve_items");
        for (let i = 0; i < reserveItems.length; i++) {
            /* getElementsTagNameを使う*/
            reserveItems[i].classList.add("status-available")
        }

        log.debug(this.state.seats_status);

        this.state.seats_status.forEach((currentValue) => {
            log.debug(currentValue)
            let generateId = "reserve_" + currentValue.item_id;
            if(document.getElementById(generateId)){
                let list = document.getElementById(generateId);
                let elementList = list.children;

                let status_class;
                log.debug(currentValue.object_status);
                switch (currentValue.object_status) {
                    case "1":
                        status_class = "status-available";
                        break;
                    case "2":
                        status_class = "status-commingsoon";
                        break;
                    case "3":
                        status_class = "status-full";
                        break;
                    default:
                        status_class = "status-available";
                        break;
                }

                for (let i = 0; i < elementList.length; i++) {
                    elementList[i].classList.add(status_class)
                }

            }

        })


    }

    handleChangeFloor = () =>{
        let floorPath;

        let updatedCurrentFloor = this.state.currentFloor;
        if(updatedCurrentFloor === 4) {
            updatedCurrentFloor = 0;
        }else{
            updatedCurrentFloor = updatedCurrentFloor +1 ;
        }

        switch(updatedCurrentFloor){
            case 0:
                floorPath = process.env.PUBLIC_URL + '/svg/icommons-map-b1.svg';
                break;
            case 1:
                floorPath = process.env.PUBLIC_URL + '/svg/icommons-map-1f.svg';
                break;
            case 2:
                floorPath = process.env.PUBLIC_URL + '/svg/icommons-map-2f.svg';
                break;
            case 3:
                floorPath = process.env.PUBLIC_URL + '/svg/icommons-map-3f.svg';
                break;
            case 4:
                floorPath = process.env.PUBLIC_URL + '/svg/icommons-map-4f.svg';
                break;
        }

        this.setState({
            currentFloor: updatedCurrentFloor,
            floorPath: floorPath
        })
        this.props.getStatus();
    }

    render() {
        return (
            <div className="App" onClick={this.handleChangeFloor}>
                {
                    this.state.isFetching ? null :
                        <ReactCSSTransitionGroup
                            transitionName="grid"
                            transitionAppear={true}
                            transitionAppearTimeout={1000}
                            transitionEnterTimeout={1000}
                            transitionLeaveTimeout={2000}>
                            <ReactSVG
                                path={this.state.floorPath}
                                callback={this.handleSvgLoaded}
                                className="map_svg"
                                style={{width: this.state.width, height: this.state.height}}
                            />
                            <IntervalTimer
                                enabled={true}
                                callback = {this.handleChangeFloor}
                                timeout={20000}
                            />

                        </ReactCSSTransitionGroup>
                }

            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapOnly)
