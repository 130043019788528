/**
 * Created by sibaservice on 2017/05/11.
 */
import React, {Component} from 'react';
import {connect, store} from 'react-redux'
import * as Actions from '../actions/actions'
import moment from 'moment'
import "moment/locale/ja";
import log from '../utils/logger';
import DetailDialog from './DetailDialog';
import "../scss/GridPattern3.scss"
import NotFound from "./404";
import IntervalTimer from "./utils/IntervalTimer";
import TimeView from "./utils/TimeView";
import CardArticle from "./CardArticle";
import QRCode from 'react-qr-component';
import FullscreenView from "./FullscreenView";

const WALL_CONTENT_CAPACITY_SIZE = 16; //1画面に表示する記事の数
const WALL_SHOW_QR_CODE = true; //QRコードを表示するかしないか

const mapStateToProps = (state, props) => {
    return {
        screenSize: state.screenSize,
        topics: state.topics,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTopics: (data) => {
            let postData = {SORT_FOR_SIGNAGE: true};
            dispatch(Actions.Connection.topics.request("post", postData));
        }
    }
};


class GridPattern3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            day: moment().format('YYYY年M月D日 ddd'),
            time: moment().format('H:mm'),
            open: false,
            photoIndex: 0,
            isOpen: false,
            isFetching: true,
            categoryId: props.categoryId,

            //currentのtopics
            currentTopicsArray: [],
            //topicsの全ての情報topicsArray
            allTopicsArray: [[]],
            //topicsのカウント（番目)
            currentWallCount: 0,
            //topicsの現在のwall枚数
            allWallCount: 1,

            //loop回数
            loop: 0,

            //以下intervalシステム用
            destroy: true,
            enabled: true,
            timeout: 30000,
            callback: this.changeWall,

        }
        this.props.getTopics(props.categoryId);
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.categoryId !== this.state.categoryId) {
            this.setState({
                categoryId: nextProps.categoryId,
                currentWallCount: 0,
                currentTopicsArray: [],
            })
            this.props.getTopics(nextProps.categoryId);
        } else {
            //todo 調整
            if (!nextProps.topics.meta.fetch) {
                if (!nextProps.topics.error) {
                    if (nextProps.topics.payload !== null) {
                        this.setState({
                            allTopicsArray: nextProps.topics.payload,
                            isFetching: nextProps.topics.meta.fetch
                        });
                        if (nextProps.topics.payload.length !== 0) {
                            this.generateTopics(nextProps.topics.payload);
                        } else {
                            this.setState({
                                destroy: true
                            })
                        }
                    }
                } else {
                    setTimeout(() => this.props.getTopics(nextProps.categoryId), 10000);
                }
            } else {
                this.setState({
                    allTopicsArray: [],
                    isFetching: nextProps.topics.meta.fetch
                })
            }
        }
    }

    componentDidMount() {
    }


    //全画面表示のときのリロード処理
    reload = () => {
        this.props.getTopics(this.state.categoryId);
    }

    changeWall = () => {
        //一時的にprogress
        this.setState({
            isFetching: true,
            count: this.state.count + 1,
        });

        const {currentWallCount, allWallCount} = this.state;
        if (currentWallCount === allWallCount - 1) {
            if (this.state.loop === 3) {
                if (this.state.categoryId === "headline") {
                    this.props.getTopics(this.state.categoryId);
                    return;
                }
                this.props.changeMenu(1);
                return;
            }
            this.setState({
                loop: this.state.loop + 1,
                currentWallCount: 0,
                currentTopicsArray: this.state.allTopicsArray[0]
            });
        } else {
            this.setState({
                currentWallCount: currentWallCount + 1,
                currentTopicsArray: this.state.allTopicsArray[currentWallCount + 1]
            });
        }

        setTimeout(() => {
            this.setState({
                isFetching: false
            })
        }, 1000)

    };

    onToggleDestroy = (bool) => {
        this.setState({destroy: bool, enabled: !bool});
    };

    handleOpen = (data) => {
        this.setState({
            open: true,
            data: data
        });
        this.onToggleDestroy(true);
    };

    handleClose = () => {
        this.setState({open: false});
        this.onToggleDestroy(false);
    };


    //デフォルトのtopicsArrayを利用してtopicsを生成
    generateTopics(topics) {
        let topicsArray = [];
        log.debug(topics.length)
        let mainLoop = Math.ceil(topics.length / WALL_CONTENT_CAPACITY_SIZE);
        let amari = topics.length % WALL_CONTENT_CAPACITY_SIZE;
        log.debug(mainLoop)
        log.debug(amari);
        for (let i = 0; i < mainLoop; i++) {
            if (i !== mainLoop - 1) {
                topicsArray.push(topics.slice(i * WALL_CONTENT_CAPACITY_SIZE, i * WALL_CONTENT_CAPACITY_SIZE + WALL_CONTENT_CAPACITY_SIZE + 1))
            } else {
                if (amari !== 0) {
                    topicsArray.push(topics.slice(i * WALL_CONTENT_CAPACITY_SIZE, i * WALL_CONTENT_CAPACITY_SIZE + amari + 1))
                    if (i !== 0) {
                        topicsArray.push(topics.slice((i - 1) * WALL_CONTENT_CAPACITY_SIZE), (i - 1) * WALL_CONTENT_CAPACITY_SIZE + WALL_CONTENT_CAPACITY_SIZE - amari + 1)
                    }
                } else {
                    topicsArray.push(topics.slice(i * WALL_CONTENT_CAPACITY_SIZE, i * WALL_CONTENT_CAPACITY_SIZE + WALL_CONTENT_CAPACITY_SIZE + 1))
                }
            }
        }
        this.setState({
            currentWallCount: 0,
            currentTopicsArray: topicsArray[0],
            allTopicsArray: topicsArray,
            allWallCount: mainLoop
        })
    }


    render() {
        let component;

        if (this.state.isFetching) {
            component = (
                <div className="App-left-component" id="grid-pattern-3" style={{position: "relative"}}>
                    <div className="sk-folding-cube">
                        <div className="sk-cube1 sk-cube"/>
                        <div className="sk-cube2 sk-cube"/>
                        <div className="sk-cube4 sk-cube"/>
                        <div className="sk-cube3 sk-cube"/>
                    </div>
                </div>
            )
        } else {
            if (this.state.allTopicsArray.length === 1 && this.state.currentTopicsArray[0].full_screen) {
                console.log(this.state.currentTopicsArray[0])
                component = (
                    <div>
                        <FullscreenView
                            handleReload={this.reload}
                            data={this.state.currentTopicsArray[0]}
                            screenSize={this.props.screenSize}/>
                    </div>
                )
            } else if (this.state.allTopicsArray.length !== 0) {
                component = (
                    <div className="grid-container" id="grid-pattern-3">
                        <div className="top-right">
                            {this.state.currentTopicsArray.length > 0 ?
                                <CardArticle
                                    screenSize={this.props.screenSize}
                                    showDetail={(data) => this.handleOpen(data)}
                                    data={this.state.currentTopicsArray[0]}/>
                                :
                                null
                            }

                        </div>
                        <div className="top-center">
                            {this.state.currentTopicsArray.length > 1 ?
                                <CardArticle
                                    screenSize={this.props.screenSize}
                                    showDetail={(data) => this.handleOpen(data)}
                                    data={this.state.currentTopicsArray[1]}/>
                                :
                                null
                            }
                        </div>
                        <div className="top-left">
                            {this.state.currentTopicsArray.length > 2 ?
                                <CardArticle
                                    screenSize={this.props.screenSize}
                                    showDetail={(data) => this.handleOpen(data)}
                                    data={this.state.currentTopicsArray[2]}/>
                                :
                                null
                            }
                        </div>
                        <div className="middle-right">
                            {this.state.currentTopicsArray.length > 3 ?
                                <CardArticle
                                    screenSize={this.props.screenSize}
                                    showDetail={(data) => this.handleOpen(data)}
                                    data={this.state.currentTopicsArray[3]}/>
                                :
                                null
                            }
                        </div>
                        <div className="middle-center">
                            {this.state.currentTopicsArray.length > 4 ?
                                <CardArticle
                                    screenSize={this.props.screenSize}
                                    showDetail={(data) => this.handleOpen(data)}
                                    data={this.state.currentTopicsArray[4]}/>
                                :
                                null
                            }
                        </div>
                        <div className="middle-left">
                            {this.state.currentTopicsArray.length > 5 ?
                                <CardArticle
                                    screenSize={this.props.screenSize}
                                    showDetail={(data) => this.handleOpen(data)}
                                    data={this.state.currentTopicsArray[5]}/>
                                :
                                null
                            }
                        </div>
                        <>
                            {WALL_SHOW_QR_CODE ?
                                <>
                                    <div className="bottom-right-1">
                                        <div className="card_qr_box">
                                            <div className="qr_code_headline">
                                                <h4>記事を投稿してみよう！</h4>
                                                <p>QRコードを読み込み記事を投稿してみよう。<br/>あなたの記事がWallに載るかも？</p>
                                            </div>
                                            <div className="qr_code_image">
                                                <QRCode value={`https://${window.location.hostname}/contribution/`}
                                                        fgColor={"#264c8b"} bgColor={"#FFFFFF"} size={80} level={"L"}/>
                                            </div>
                                            <div
                                                className="qr_code_uri">{`https://${window.location.hostname}/contribution/`}</div>
                                        </div>
                                    </div>
                                    <div className="bottom-right-2">
                                        <div id={"card_time"}>
                                            <TimeView/>
                                        </div>
                                    </div>
                                    <div className="bottom-right-3">
                                        {this.state.currentTopicsArray.length > 6 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[6]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-right-4">
                                        {this.state.currentTopicsArray.length > 7 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[7]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-center-1">
                                        {this.state.currentTopicsArray.length > 8 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[8]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-center-2">
                                        {this.state.currentTopicsArray.length > 9 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[9]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-center-3">
                                        {this.state.currentTopicsArray.length > 10 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[10]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-center-4">
                                        {this.state.currentTopicsArray.length > 11 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[11]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-left-1">
                                        {this.state.currentTopicsArray.length > 12 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[12]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-left-2">
                                        {this.state.currentTopicsArray.length > 13 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[13]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-left-3">
                                        {this.state.currentTopicsArray.length > 14 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[14]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-left-4">
                                        {this.state.currentTopicsArray.length > 15 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[15]}/>
                                            :
                                            null
                                        }
                                    </div>
                                </>
                                :
                                <>
                                    <div className="bottom-right-1">
                                        {this.state.currentTopicsArray.length > 6 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[6]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-right-2">
                                        <div id={"card_time"}>
                                            <TimeView/>
                                        </div>
                                    </div>
                                    <div className="bottom-right-3">
                                        {this.state.currentTopicsArray.length > 7 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[7]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-right-4">
                                        {this.state.currentTopicsArray.length > 8 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[8]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-center-1">
                                        {this.state.currentTopicsArray.length > 9 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[9]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-center-2">
                                        {this.state.currentTopicsArray.length > 10 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[10]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-center-3">
                                        {this.state.currentTopicsArray.length > 11 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[11]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-center-4">
                                        {this.state.currentTopicsArray.length > 12 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[12]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-left-1">
                                        {this.state.currentTopicsArray.length > 13 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[13]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-left-2">
                                        {this.state.currentTopicsArray.length > 14 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[14]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-left-3">
                                        {this.state.currentTopicsArray.length > 15 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[15]}/>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="bottom-left-4">
                                        {this.state.currentTopicsArray.length > 16 ?
                                            <CardArticle
                                                screenSize={this.props.screenSize}
                                                showDetail={(data) => this.handleOpen(data)}
                                                data={this.state.currentTopicsArray[16]}/>
                                            :
                                            null
                                        }
                                    </div>
                                </>
                            }
                        </>
                        {/*
                        <div className={"balloon"}>
                            <img className={"balloon-image"} src={process.env.PUBLIC_URL + '/svg/touch.svg'} />
                            <p className={"balloon-text"}>
                                記事をタッチして<br/>
                                詳細を閲覧しよう
                            </p>
                        </div>
                        */}
                        <IntervalTimer
                            enabled={this.state.enabled}
                            callback={this.changeWall}
                            timeout={this.state.timeout}
                        />
                        <DetailDialog
                            open={this.state.open}
                            handleClose={this.handleClose}
                            data={this.state.data}/>
                    </div>
                );
            } else {
                component = (
                    <div className="App-left-component" id="not_found_404">
                        <NotFound/>
                    </div>
                );
            }
        }
        return component;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GridPattern3)

