import React, {Component} from 'react';
import {connect, store} from 'react-redux'
import './scss/App.scss';
import moment from 'moment'
import MenuComponent from "./components/Menu"
import GridPatternUsageInfo from "./components/GridPatternUsageInfo"
import "moment/locale/ja";
import {TransitionMotion, spring} from 'react-motion'

import * as Actions from './actions/actions'
import Menu from "./components/demo/MenuCustom";
import GridPattern3 from "./components/GridPattern3";

const leavingSpringConfig = {stiffness: 200, damping: 50};


const mapStateToProps = (state, props) => {
    return {
        screenSize: state.screenSize
    }
};

const mapDispatchToProps = dispatch => {
    return {
        windowResize : (dataHeight,dataWidth) => {
            dispatch(Actions.resizeWindowSize(dataHeight,dataWidth))
        }
    }
};

class App extends Component {
    constructor(props) {
        super(props);

        const search = props.location.search;
        const params = new URLSearchParams(search);
        const isCursor = params.get('cursor');
        let cursorStyle = {}
        if(isCursor === "true"){
            cursorStyle = {
                cursor: "auto"
            }
        }

        this.props.windowResize(window.innerHeight,window.innerWidth);
        window.addEventListener('resize', () => {
            this.props.windowResize(window.innerHeight,window.innerWidth);
        });
        this.state = {
            day: moment().format('YYYY年Y月D日（ddd)'),
            time: moment().format('H:mm'),
            mouse: [],
            now: 't' + 0,
            currentMenuNum: 1,
            cursorStyle: cursorStyle
        }


    }

    handleClick = ({pageX, pageY}) => {
        // Make sure the state is queued and not batched.
        this.setState(() => {
            return {
                mouse: [pageX - 25, pageY - 25],
                now: 't' + Date.now(),
            };
        });
    };

    handleMouseUp = () => {
        // Make sure the state is queued and not batched.
        this.setState(() => {
            return {
                mouse: [],
                now: 't' + 0,
            };
        });
    };

    handleMouseMove = ({pageX, pageY}) => {
        // Make sure the state is queued and not batched.
        this.setState(() => {
            return {
                mouse: [pageX - 25, pageY - 25],
                now: 't' + Date.now(),
            };
        });
    };

    handleTouchMove = (e) => {
        this.handleMouseMove(e.touches[0]);
    };


    handleTouchStart = (e) => {
        this.handleClick(e.touches[0]);
    };


    handleTouchEnd = (e) => {
        this.handleMouseUp();
    };

    handleContextMenu = (e) => {
        e.preventDefault()
    }

    willLeave = (styleCell) => {
        return {
            ...styleCell.style,
            opacity: spring(0, leavingSpringConfig),
            scale: spring(2, leavingSpringConfig),
        };
    };

    changeMenu = (num) => {
        this.setState({
            currentMenuNum: num
        })
    };

    render() {

        const {mouse: [mouseX, mouseY], now} = this.state;
        const styles = mouseX == null ? [] : [{
            key: now,
            style: {
                opacity: spring(1),
                scale: spring(0),
                x: spring(mouseX),
                y: spring(mouseY),
            }
        }];

        return (
            <TransitionMotion willLeave={this.willLeave} styles={styles}>
                {cs =>
                    <div className="App"
                         style={this.state.cursorStyle}
                         onMouseDown={this.handleClick}
                         onMouseUp={this.handleMouseUp}
                         onTouchMove={this.handleTouchMove}
                         onTouchStart={this.handleTouchStart}
                         onTouchEnd={this.handleTouchEnd}
                         onContextMenu={this.handleContextMenu}>
                        {
                            cs.map(({key, style: {opacity, scale, x, y}}) =>
                                <div
                                    key={key}
                                    className="water-drop"
                                    style={{
                                        opacity: opacity,
                                        scale: scale,
                                        transform: `translate3d(${x}px, ${y}px, 0) scale(${scale})`,
                                        WebkitTransform: `translate3d(${x}px, ${y}px, 0) scale(${scale})`,
                                    }}/>
                            )
                        }
                        {(() => {
                            switch(this.state.currentMenuNum) {
                                case 1:
                                    return <GridPattern3 categoryId="headline" changeMenu={this.changeMenu}/>;
                                    break;
                                case 2:
                                    return <GridPattern3 categoryId="user_news" changeMenu={this.changeMenu}/>;
                                    break;
                                case 3:
                                    return <GridPattern3 categoryId="university_news" changeMenu={this.changeMenu}/>;
                                    break;
                                case 4:
                                    return <GridPattern3 categoryId="facebook" changeMenu={this.changeMenu}/>;
                                    break;
                                case 5:
                                    return <GridPattern3 categoryId="twitter" changeMenu={this.changeMenu}/>;
                                    break;
                                case 6:
                                    return <GridPattern3 categoryId="youtube"  changeMenu={this.changeMenu}/>;
                                    break;
                                default:
                                    //todo 後で戻す必要あり
                                    return <GridPattern3 categoryId="headline" changeMenu={this.changeMenu}/>;
                                //return <GridPatternUsageInfo changeMenu={this.changeMenu} />;
                            }
                        })()}
                    </div>
                }
            </TransitionMotion>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)

