import React, {Component} from 'react';

class IntervalTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeout: props.timeout,
            enabled: props.enabled,
            callback: props.callback
        }

        this.interval = setInterval(()=>{
            props.callback()
        },props.timeout)
    }


    componentWillReceiveProps(nextProps){
        if(!nextProps.enabled) {
            if (this.interval) {
                clearInterval(this.interval)
            }
        }else{
            if(this.state.enabled !== nextProps.enabled) {
                this.interval = setInterval(() => {
                    nextProps.callback()
                }, nextProps.timeout)
            }
        }
        this.setState({
            timeout: nextProps.timeout,
            enabled: nextProps.enabled,
            callback: nextProps.callback
        })

    }

    componentWillUnmount(){
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    render() {
        return (
            null
        )
    }
}

export default IntervalTimer