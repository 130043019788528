import React, {Component} from 'react'
import ERROR_OUTLINE from 'material-ui/svg-icons/alert/error-outline';
import RaisedButton from 'material-ui/RaisedButton';

import * as url from 'constants/api'
import QRCode from 'react-qr-component';

class Notfound extends Component {
    render() {
        return (
            <div>
                <div className="explain_box">
                    <div className="explain_box_left"><ERROR_OUTLINE/></div>
                    <div className="explain_box_right">
                        <h3>TOPICS NOT FOUND</h3>
                        <p>記事が見つかりません</p>
                        {/*
                        //todo 後で戻す
                        <div className=" notice_qrcode">
                            <div className="QRCode404">
                                <QRCode value={url.POST_URI} fgColor={"#555555"} bgColor={"transparent"} size={"200"} level={"L"}/>
                            </div>
                            <p>QRコードを読み込み記事を投稿してみよう。<br />あなたの記事がWallに載るかも？</p>
                        </div>
                        */}
                    </div>
                </div>

            </div>
        )
    }
}

export default Notfound