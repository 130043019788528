import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import log from "../utils/logger";
import ClassNames from "classnames";
import ReactPlayer from "react-player";
import Carousel from "re-carousel";
import Dots from "./utils/carousel_dots";
import Buttons from "./utils/carousel_button";
import Slider from "react-slick";
import * as Constants from "../constants/constants";
import parse from "url-parse"
import YouTube from "react-youtube";
import IntervalTimer from "./utils/IntervalTimer";

FullscreenView.propTypes = {};


function FullscreenView(props) {
    let [data, setData] = useState(props.data);
    let [isPlaying, setPlaying] = useState(false)
    let [loopCount, setLoopCount] = useState(1);
    let [swipeCount, setSwipeCount] = useState(1);
    const [previewType, setPreviewType] = useState(undefined);


    useEffect(() => {
        setData(props.data)
        setSwipeCount(1);
        setLoopCount(1);
        let imageArray = data.MEDIAS;
        console.log(imageArray)
        if (data.MEDIAS.length < 1) {
            setPreviewType(undefined);
        }
        //動画か、もしくは画像か
        if (
            data.MEDIAS.every((val) => {
                return val.TYPE !== "video"
            })
        ) {
            setPreviewType("image")
        } else {
            setPreviewType("video")
        }
    }, [data])

    if (data) {

        //カテゴリ用アイコン制御部
        const imageGalleryOuterStyle = {
            width: props.screenSize.screenWidth,
            height: props.screenSize.screenHeight
        };

        let imageArray = data.MEDIAS;
        let onlyString = imageArray.length < 1;

        let imageBox = "";
        let filteredArray = imageArray.filter((value, index) => {
            if (value.TYPE === "image") {
                return true;
            } else if (value.TYPE === "video") {
                return true;
            } else {
                //サムネイルとかがここに出力される可能性があるので注意する
                return false;
            }
        });


        const _onReady = (event) => {
            // access to player in all event handlers via event.target
            event.target.setVolume(0)
            event.target.playVideo()
        }
        const _onEnd = (event) => {
            if(loopCount >= 3){
                props.handleReload();
            }else{
                setLoopCount(loopCount + 1);
                event.target.playVideo()
            }
        }

        if (!onlyString) {

            let listItems = filteredArray.map((value, index) => {
                if (value.TYPE === "image") {
                    let reFormatUrl = Constants.ReformatImageStore(value.URL, props.screenSize.screenHeight, 0);
                    let style = {
                        backgroundImage: "url('" + reFormatUrl + "')",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        width: props.screenSize.screenWidth,
                        height: props.screenSize.screenHeight
                    };
                    return (
                        <div key={index} style={{
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            height: "100%",
                            width: "100%",
                            backgroundImage: "url('" + value.URL + "')"
                        }}/>

                    )
                } else if (value.TYPE === "video") {
                    const parser = new URL(value.URL);
                    const vId = parser.searchParams.get("v");
                    const opts = {
                        height: props.screenSize.screenHeight,
                        width: "100%",
                        playerVars: {
                            autoPlay: 1,
                            loop: 1,
                            modestbranding: 1
                        },
                    };
                    return (
                        <div key={index} style={{
                            height: props.screenSize.screenHeight,
                            width: "100%"
                        }}>
                            <YouTube
                                videoId={vId}                  // defaults -> null
                                opts={opts}
                                onReady={_onReady}
                                onEnd={_onEnd}// defaults -> {}
                            />
                        </div>
                    )
                }
            });
            imageBox = (
                <div className="image_gallery_outer" style={imageGalleryOuterStyle}>
                    {previewType === "image" &&
                    <div style={{
                        background: "none",
                        position: "absolute",
                        zIndex: 9999,
                        width: "100%",
                        height: "100%"
                    }} onClick={(e) => e.preventDefault()}/>
                    }
                    <Carousel loop={listItems.length !== 1} auto interval={30000}
                              onTransitionEnd={(prev, current, next) => {
                                  if(previewType === "image") {
                                      //0が1枚目なので1を追加して割り算かけて3週させる
                                      if ( (swipeCount + 1) / listItems.length === 3) {
                                          setSwipeCount(1);
                                          setTimeout(()=>{props.handleReload()},28000)
                                      } else {
                                          setSwipeCount(swipeCount + 1);
                                      }
                                  }
                              }}>
                        {listItems}
                    </Carousel>
                    <IntervalTimer
                        timeout={60000}
                        enabled={previewType === "image" && listItems.length === 1}
                        callback={props.handleReload}
                    />
                </div>
            )
        } else {

        }

        return (
            <div className="fullscreen"
                 style={{height: props.screenSize.screenHeight}}>
                {imageBox}
            </div>
        );
    } else {
        return <div>sss</div>;
    }
}

export default FullscreenView;