/**
 * Created by sibaservice on 2017/05/11.
 */
import React, {Component} from 'react';
import {connect, store} from 'react-redux'

import moment from 'moment'
import "moment/locale/ja";
import log from '../utils/logger';
import CardComponent from './Card'
import {Card} from 'material-ui/Card';
import DetailDialog from './DetailDialog';
import ClassNames from 'classnames';
import ReactSVG from 'react-svg'
import Map from './Map'
import * as Actions from '../actions/actions'


import "../scss/GridPatternUsageInfo.scss"
import Slider from "react-slick";


const mapStateToProps = (state, props) => {
    log.debug(state.screenSize)
    return {
        screenSize: state.screenSize,
        status: state.status
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getStatus: () => {
            dispatch(Actions.Connection.status.request("get"))
        }
    }
};


class GridPatternUsageInfo extends Component {
    constructor(props) {
        super(props);
        const list = [];
        this.state = {
            day: moment().format('YYYY年M月D日 ddd'),
            time: moment().format('H:mm'),
            currentMenuNum: 1,
            open: false,
            photoIndex: 0,
            isOpen: false,

            mapHeight: this.props.screenSize.screenHeight / 12 * 8 - 10,
            equipments_status: [],
            isFetching: true
        };
        props.getStatus();
    }


    generateUsageInfoList = (list) => {
        let countList = Math.ceil(list.length / 10);
        let stubArray = [];
        for (let i=0; i<countList; i++) {
            stubArray[i] = i;
        }
        const innerDiv = stubArray.map((val,i) => {
            //余りについては最後のarray以外の場合は常に１０として換算
            let amari = stubArray.length !== i + 1 ?  10: list.length % 10 ;
            let digit;
            if (i === 0) {
                digit = 0;
            } else {
                digit = i * 10;
            }
            return (
                <div className={"marquee"}>
                    <table>
                        <tbody>
                        <tr>
                            <th className="table_main">備品名</th>
                            <th className="table_sub"><span>空状況</span></th>
                            <th className="table_main">備品名</th>
                            <th className="table_sub"><span>空状況</span></th>
                        </tr>
                        <tr>
                            <td className="table_main">
                                {(() => {
                                    if (amari > 0) {
                                        return list[digit + 0].item_name;
                                    } else {
                                        return "";
                                    }
                                })()}
                            </td>
                            <td className="table_sub">
                                        <span className="molecule">
                                        {(() => {
                                            if (amari > 0) {
                                                return list[digit + 0].object_total_count - list[digit + 0].object_used_count;
                                            } else {
                                                return "";
                                            }
                                        })()}
                                        </span>
                                <span className="slash">/</span>
                                <span className="denominator">
                                            {(() => {
                                                if (amari > 0) {
                                                    return list[digit + 0].object_total_count;
                                                } else {
                                                    return "";
                                                }
                                            })()}
                                        </span>
                            </td>
                            <td className="table_main">
                                {(() => {
                                    if (amari > 1) {
                                        return list[digit + 1].item_name;
                                    } else {
                                        return "";
                                    }
                                })()}
                            </td>
                            <td className="table_sub">
                                        <span className="molecule">
                                        {(() => {
                                            if (amari > 1) {
                                                return list[digit + 1].object_total_count - list[digit + 1].object_used_count;
                                            } else {
                                                return "";
                                            }
                                        })()}
                                        </span>
                                <span className="slash">/</span>
                                <span className="denominator">
                                            {(() => {
                                                if (amari > 1) {
                                                    return list[digit + 1].object_total_count;
                                                } else {
                                                    return "";
                                                }
                                            })()}
                                        </span>
                            </td>
                        </tr>
                        <tr>

                            <td className="table_main">
                                {(() => {
                                    if (amari > 2) {
                                        return list[digit + 2].item_name;
                                    } else {
                                        return "";
                                    }
                                })()}
                            </td>
                            <td className="table_sub">
                                        <span className="molecule">
                                        {(() => {
                                            if (amari > 2) {
                                                return list[digit + 2].object_total_count - list[digit + 2].object_used_count;
                                            } else {
                                                return "";
                                            }
                                        })()}
                                        </span>
                                <span className="slash">/</span>
                                <span className="denominator">
                                            {(() => {
                                                if (amari > 2) {
                                                    return list[digit + 2].object_total_count;
                                                } else {
                                                    return "";
                                                }
                                            })()}
                                        </span>
                            </td>

                            <td className="table_main">
                                {(() => {
                                    if (amari > 3) {
                                        return list[digit + 3].item_name;
                                    } else {
                                        return "";
                                    }
                                })()}
                            </td>
                            <td className="table_sub">

                                    <span className="molecule">
                                        {(() => {
                                            if (amari > 3) {
                                                return list[digit + 3].object_total_count - list[digit + 3].object_used_count;
                                            } else {
                                                return "";
                                            }
                                        })()}
                                        </span>
                                <span className="slash">/</span>
                                <span className="denominator">
                                            {(() => {
                                                if (amari > 3) {
                                                    return list[digit + 3].object_total_count;
                                                } else {
                                                    return "";
                                                }
                                            })()}
                                        </span>
                            </td>
                        </tr>
                        <tr>

                            <td className="table_main">
                                {(() => {
                                    if (amari > 4) {
                                        return list[digit + 4].item_name;
                                    } else {
                                        return "";
                                    }
                                })()}
                            </td>
                            <td className="table_sub">
                                        <span className="molecule">
                                        {(() => {
                                            if (amari > 4) {
                                                return list[digit + 4].object_total_count - list[digit + 4].object_used_count;
                                            } else {
                                                return "";
                                            }
                                        })()}
                                        </span>
                                <span className="slash">/</span>
                                <span className="denominator">
                                            {(() => {
                                                if (amari > 4) {
                                                    return list[digit + 4].object_total_count;
                                                } else {
                                                    return "";
                                                }
                                            })()}
                                        </span>
                            </td>

                            <td className="table_main">
                                {(() => {
                                    if (amari > 5) {
                                        return list[digit + 5].item_name;
                                    } else {
                                        return "";
                                    }
                                })()}
                            </td>
                            <td className="table_sub">
                                        <span className="molecule">
                                        {(() => {
                                            if (amari > 5) {
                                                return list[digit + 5].object_total_count - list[digit + 5].object_used_count;
                                            } else {
                                                return "";
                                            }
                                        })()}
                                        </span>
                                <span className="slash">/</span>
                                <span className="denominator">
                                            {(() => {
                                                if (amari > 5) {
                                                    return list[digit + 5].object_total_count;
                                                } else {
                                                    return "";
                                                }
                                            })()}
                                        </span>
                            </td>
                        </tr>
                        <tr>

                            <td className="table_main">
                                {(() => {
                                    if (amari > 6) {
                                        return list[digit + 6].item_name;
                                    } else {
                                        return "";
                                    }
                                })()}
                            </td>
                            <td className="table_sub">
                                        <span className="molecule">
                                        {(() => {
                                            if (amari > 6) {
                                                return list[digit + 6].object_total_count - list[digit + 6].object_used_count;
                                            } else {
                                                return "";
                                            }
                                        })()}
                                        </span>
                                <span className="slash">/</span>
                                <span className="denominator">
                                            {(() => {
                                                if (amari > 6) {
                                                    return list[digit + 6].object_total_count;
                                                } else {
                                                    return "";
                                                }
                                            })()}
                                        </span>
                            </td>

                            <td className="table_main">
                                {(() => {
                                    if (amari > 7) {
                                        return list[digit + 7].item_name;
                                    } else {
                                        return "";
                                    }
                                })()}
                            </td>
                            <td className="table_sub">
                                        <span className="molecule">
                                        {(() => {
                                            if (amari > 7) {
                                                return list[digit + 7].object_total_count - list[digit + 7].object_used_count;
                                            } else {
                                                return "";
                                            }
                                        })()}
                                        </span>
                                <span className="slash">/</span>
                                <span className="denominator">
                                            {(() => {
                                                if (amari > 7) {
                                                    return list[digit + 7].object_total_count;
                                                } else {
                                                    return "";
                                                }
                                            })()}
                                        </span>
                            </td>
                        </tr>
                        <tr>

                            <td className="table_main">
                                {(() => {
                                    if (amari > 8) {
                                        return list[digit + 8].item_name;
                                    } else {
                                        return "";
                                    }
                                })()}
                            </td>
                            <td className="table_sub">
                                        <span className="molecule">
                                        {(() => {
                                            if (amari > 8) {
                                                return list[digit + 8].object_total_count - list[digit + 8].object_used_count;
                                            } else {
                                                return "";
                                            }
                                        })()}
                                        </span>
                                <span className="slash">/</span>
                                <span className="denominator">
                                            {(() => {
                                                if (amari > 8) {
                                                    return list[digit + 8].object_total_count;
                                                } else {
                                                    return "";
                                                }
                                            })()}
                                        </span>
                            </td>

                            <td className="table_main">
                                {(() => {
                                    if (amari > 9) {
                                        return list[digit + 9].item_name;
                                    } else {
                                        return "";
                                    }
                                })()}
                            </td>
                            <td className="table_sub">
                                        <span className="molecule">
                                        {(() => {
                                            if (amari > 9) {
                                                return list[digit + 9].object_total_count - list[digit + 9].object_used_count;
                                            } else {
                                                return "";
                                            }
                                        })()}
                                        </span>
                                <span className="slash">/</span>
                                <span className="denominator">
                                            {(() => {
                                                if (amari > 9) {
                                                    return list[digit + 9].object_total_count;
                                                } else {
                                                    return "";
                                                }
                                            })()}
                                        </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            );

        });

        return innerDiv;
    }


    componentWillReceiveProps(nextProps) {
        if (!nextProps.status.meta.fetch) {
            if (!nextProps.status.error) {
                if (nextProps.status.payload !== null) {
                    this.setState({
                        isFetching: nextProps.status.meta.fetch
                    })
                    log.debug(nextProps.status.payload);

                    //equipments_status
                    //seats_status
                    this.setState({
                        equipments_status: nextProps.status.payload.equipments_status,
                        seats_status: nextProps.status.payload.seats_status,
                    })
                }
            }
        } else {
            this.setState({
                isFetching: nextProps.status.meta.fetch
            })
        }


    }


    handleOpen = (data) => {
        this.setState({
            open: true,
            data: data
        });
    };
    handleClose = () => {
        this.setState({open: false});
    };

    handleImageLoad = (event) => {
        console.log('Image loaded ', event.target)
    }


    render() {
        log.debug(this.state.data)
        const settings = {
            arrows: false,
            dots: false,
            infinite: true,
            touchMove:  true,
            focusOnSelect: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: true,
            autoplaySpeed: 10000,
            autoplay: true,
            className: "fixturesSlide",
            beforeChange: function(currentSlide, nextSlide) {
                console.log("before change", currentSlide, nextSlide);
            },
            afterChange: function(currentSlide) {
                console.log("after change", currentSlide);
            }
        };
        return (
            <div className="App-left-component" id="grid-pattern-usage">
                <div id="card_map" className="card card_map">
                    <Map
                        height={this.state.mapHeight}
                        seats_status={this.state.seats_status}
                        isFetching={this.state.isFetching}
                    />
                </div>
                <div id="card_usage_table" className="card card_usage_table">
                    <Card className="card_outer">
                        <div className="usage_table_left_container">
                            <div className="usage_table_title">
                                <span className="commons">備品利用状況</span>
                            </div>
                        </div>
                        <div className="usage_table_right_container">
                            <div className="full_container">
                                <Slider {...settings}>
                                    {this.generateUsageInfoList(this.state.equipments_status)}
                                </Slider>
                            </div>
                        </div>
                    </Card>
                </div>

                <DetailDialog
                    open={this.state.open}
                    handleClose={this.handleClose}
                    data={this.state.data}/>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GridPatternUsageInfo)
