import log from "utils/logger"
import ClassNames from "classnames";

export const DATA_PAYLOAD = {
    API_ENDPOINT: process.env.REACT_APP_API_DOMAIN ,
    API_SERVICE_NAME: "demo_data",
    CAST_STATUS: 0,
    CATEGORY_ID: 1,
    CREATOR_ID: 1,
    ID_ON_SNS: "",
    MEDIAS: [],
    PATROLLED: "ACCEPTED",
    TOPIC_AUTHOR_NAME: "",
    TOPIC_AVAILABLE_FROM: "",
    TOPIC_AVAILABLE_TO: "",
    TOPIC_BODY: "",
    TOPIC_CREATED: "",
    TOPIC_ID: 0,
    TOPIC_SORT_NO: 0,
    TOPIC_TITLE:"",
    VOTES:{
        WALL_LIKES: 0,
        YOUTUBE_LIKES: 0,
        YOUTUBE_DISLIKES: 0
    }
}


export function getClassNames(data, isIcon){
    return ClassNames({
        "twitter": data === 1,
        "facebook": data === 2,
        "youtube": data === 3,
        "instagram" : data === 4,
        "user_news": data === 90,
        "university_news": data >= 100,
        "headline": data === 51,
        "icon": isIcon,
        "cardIcon": isIcon
    });
}


export function ReformatImageStore(url,height,width){
    const replaceString = `getimage(dh=${height},dw=${width},da=s)`;
    console.log(url.replace(/getimage-origin/,replaceString))
    return url.replace(/getimage-origin/,replaceString);
}


/**
 * API用のドメインを自身のホスト名から生成
 * @returns {string}
 */
export const generateApiDomain = (openWithBrowser) => {
    function changeApi(match, p1, p2, p3, offset, string) {
        return `${p1}-api${p3}`;
    }
    const hostname = window.location.hostname;
    const regex = /^(.*?)(-signage|-wall)(.*?\..*?)$/;
    if (hostname === "localhost") {
        return !openWithBrowser ? "" : process.env.REACT_APP_API_DOMAIN;
    } else {
        return `https://${hostname.replace(regex, changeApi)}`;
    }
};