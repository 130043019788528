/**
 * Created by sibaservice on 2017/05/11.
 */
import React, {Component} from 'react';
import {connect, store} from 'react-redux'
import PropTypes from 'prop-types';
import log from '../utils/logger';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import {PinchView} from 'react-pinch-zoom-pan'
import IconButton from 'material-ui/IconButton';
import HighLightOff from 'material-ui/svg-icons/action/highlight-off';
import {Scrollbars} from 'react-custom-scrollbars';
import * as Constants from '../constants/constants';
import Carousel from 're-carousel'
import Dots from './utils/carousel_dots'
import Buttons from './utils/carousel_button'
import ReactPlayer from 'react-player'
import ClassNames from 'classnames';
import moment from "moment/moment";
import {withRouter} from 'react-router-dom';
import {getClassNames} from "../constants/constants";

const mapStateToProps = (state, props) => {
    log.debug(state.screenSize)
    return {
        screenSize: state.screenSize
    }
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
};


class DetailDialog extends Component {
    constructor(props) {
        super(props);

        const search = props.location.search;
        const params = new URLSearchParams(search);
        const isCursor = params.get('cursor');
        let cursorStyle = {}
        if(isCursor === "true"){
            cursorStyle = {
                cursor: "auto"
            }
        }

        this.state = {
            top: 0,
            open: props.open,
            data: Constants.DATA_PAYLOAD,
            cursorStyle: cursorStyle
        }
    }

    componentDidMount(){
        let dialog = document.getElementsByClassName("dialog_wrapper");
        console.log(dialog);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data) {
            this.setState({
                data: nextProps.data,
                open: nextProps.open,
            })
        } else {
            this.setState({
                open: nextProps.open
            })
        }
    }


    render() {
        const customContentStyle = {
            width: "90%",
            maxWidth: "90%",
            height: "90%",
            maxHeight: "90%"
        };

        log.debug(this.state.data);
        if (this.state.data.TOPIC_TITLE) {
            log.debug(this.state.data);
            //カテゴリ用アイコン制御部
            let cxDialog = getClassNames(this.state.data.CATEGORY_ID,false);
            let cxIcon = getClassNames(this.state.data.CATEGORY_ID,false);

            const customContentStyle = {
                width: "90%",
                maxWidth: "90%",
                height: "90%",
                maxHeight: "90%"
            };
            const imageGalleryOuterStyle = this.state.data.TOPIC_BODY ? {
                width: this.props.screenSize.dialogDetailBodyWidth * 0.6,
                height: this.props.screenSize.dialogDetailBodyHeight
            }:{
                width: this.props.screenSize.dialogDetailBodyWidth,
                height: this.props.screenSize.dialogDetailBodyHeight
            }
            const descriptionOuterStyle = {
                width: this.props.screenSize.dialogDetailBodyWidth * 0.4,
            }

            const settings = {
                dots: true,
                arrows: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
            };


            let imageArray = this.state.data.MEDIAS;
            let onlyString = imageArray.length < 1;
            //todo 複数枚数のときと、単数枚数のときでのSlider制御文の追加

            let imageBox = "";
            let filteredArray = imageArray.filter((value,index) => {
                if (value.TYPE === "image") {
                    return true;
                } else if (value.TYPE === "video") {
                    log.debug(value.URL + ":DEBUG_URL")
                    return true;
                }else{
                    return false;
                }
            })

            if (!onlyString) {
                let listItems = filteredArray.map((value, index) => {
                    if (value.TYPE === "image") {
                        return (
                            <div  key={index} style={{backgroundPosition:"center",backgroundRepeat: "no-repeat",backgroundSize:"contain",height:"100%",width:"100%",backgroundImage:"url('"+ value.URL+ "')"}}/>
                        )
                    } else if (value.TYPE === "video") {
                        return (
                            <div key={index}>
                                <div style={{background:"none",position:"absolute",zIndex:9999,width:"100%",height:"100%"}} onClick={(e) => e.preventDefault()} />
                                <ReactPlayer
                                    id="iframe_outer"
                                    ref={player => {
                                        this.player = player
                                    }}
                                    controls={false}
                                    onReady={()=>{}}
                                    loop={true}
                                    width={"100%"}
                                    height={this.props.screenSize.dialogDetailBodyHeight - 60}
                                    url={value.URL}
                                    playing
                                />
                            </div>
                        )
                    }
                });
                imageBox = (
                    <div className="image_gallery_outer" style={imageGalleryOuterStyle}>
                        <Carousel widgets={[Dots, Buttons]}>
                            {listItems}
                        </Carousel>
                    </div>
                )
            } else {

            }

            return (
                <Dialog
                    modal={false}
                    contentStyle={customContentStyle}
                    paperClassName={cxDialog}
                    open={this.state.open}
                    className="dialog_wrapper"
                >
                    <div className="dialog_body"
                         style={this.state.cursorStyle}
                         onContextMenu={(e) => {e.preventDefault()}} >
                        <div className="dialog_title_outer">
                            <div className={cxIcon}/>
                            <div className="dialog_title">{this.state.data.TOPIC_AUTHOR_NAME}</div>
                            <IconButton style={{cursor:"none"}}
                                className="dialog_title_close_outer">
                                <HighLightOff
                                    onClick={this.props.handleClose}
                                />
                            </IconButton>
                        </div>
                        <div className="dialog_content_outer"
                             style={{height: this.props.screenSize.dialogDetailBodyHeight}}>
                            {imageBox}
                            {this.state.data.TOPIC_BODY ?
                                <div className="description_outer" style={descriptionOuterStyle}>
                                    <h2 className="description_title">
                                        {this.state.data.TOPIC_TITLE}
                                    </h2>
                                    <div className="description_body">
                                        {this.state.data.TOPIC_BODY.replace(/\\n/g, '\r\n')}
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </Dialog>
            );
        } else {
            return (
                <Dialog
                    modal={true}
                    contentStyle={customContentStyle}
                    open={this.state.open}
                    className="dialog_wrapper">
                    <div/>
                </Dialog>
            )
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DetailDialog))
