import axios from 'axios'
import * as Actions from '../actions/actions'
import log from 'utils/logger.js'
import * as url from '../constants/api'
//import { showLoading, hideLoading } from 'react-redux-loading-bar'


export const connection = store => next => action => {
    next(action)
    log.debug(action);
    if(action.type.match("REQUEST$")){
        if(action.payload.method !== null && action.payload.method === "post"){
            store.dispatch(Actions.Loading(true));

            axios({
                method: 'post',
                url: action.payload.api,
                timeout: 30000,
                withCredentials: true,
                headers: {'Content-Type': 'application/json','x-api-key':'DONT_PANIC'},
                data: JSON.stringify(action.payload.data)
            })
                .then(res => {
                    store.dispatch(Actions.Loading(false));
                    //promiseのrender波及予防用timeout
                    setTimeout(() => {
                        const responseJson = res.data;
                        let type = action.type.replace(/REQUEST$/, "RECEIVE")
                        if(responseJson.response === "success"){
                            next({
                                type:type,
                                meta:{
                                    fetch:false
                                },
                                payload: responseJson.data
                            })
                        }else{
                            next({
                                type:type,
                                error: true,
                                meta:{
                                    fetch:false
                                },
                                payload: responseJson.message + "(CODE:" + responseJson.code + ")"
                            })
                        }
                    });
                })
                .catch(error => {
                    store.dispatch(Actions.Loading(false));
                    log.debug("middleware error2")
                    log.debug(error)
                    let type = action.type.replace(/REQUEST$/, "RECEIVE")
                    //todo Error Switch Handling
                    next({
                        type:type,
                        meta:{
                            fetch:false
                        },
                        error: true,
                        payload: error.message
                    })
                })
            ;
        }
        log.debug(action, "middleware action")
        if (action.payload.method !== null && action.payload.method === 'get') {
            //store.dispatch(showLoading())
            axios({
                method: 'get',
                url: action.payload.api,
                timeout: 30000,
                responseType: 'json',
                withCredentials: true,
                headers: {'Content-Type': 'application/json'},
            })
                .then(res => {

                    store.dispatch(Actions.Loading(false));
                    //promiseのrender波及予防用timeout
                    setTimeout(() => {
                        const responseJson = res.data;
                        let type = action.type.replace(/REQUEST$/, "RECEIVE")
                        if(responseJson.response === "success"){
                            log.debug("middleware success")
                            next({
                                type:type,
                                meta:{
                                    fetch:false
                                },
                                payload: responseJson.data
                            })
                        }else{
                            next({
                                type:type,
                                error: true,
                                meta:{
                                    fetch:false
                                },
                                payload: responseJson.message + "(CODE:" + responseJson.code + ")"
                            })
                        }
                    });

                })
                .catch(error => {
                    store.dispatch(Actions.Loading(false));
                    log.debug("middleware error2")
                    log.debug(error)
                    let type = action.type.replace(/REQUEST$/, "RECEIVE")
                    //todo Error Switch Handling
                    next({
                        type:type,
                        meta:{
                            fetch:false
                        },
                        error: true,
                        payload: error.message
                    })
                });
        }
    }


}