/**
 * APIアクセス先URL
 */

import {generateApiDomain} from "./constants";

/**
 *
 * 環境構築用apiの構成
 */
let environmentDomain_wall;
let environmentDomain_ist;

let stubDomain;
const VERSION = "/v2/wall";
const CLIENT_HOST    = process.env.NODE_ENV === "production" ? generateApiDomain() : "";
let postUri;// QRコード作成用投稿URI

if (process.env.NODE_ENV === "production") {
    // Production
    environmentDomain_wall = CLIENT_HOST + "/api" + VERSION;
    //todo 道本に確認 istとの同居環境について
    environmentDomain_ist = "https://stable-ist-signage.staging.siba-service.jp/api";
    stubDomain = "";
    postUri = "";
} else {
    environmentDomain_wall = "/api" + VERSION;
    environmentDomain_ist = "/api";
    stubDomain = "";
    postUri = "";
    // // Staging
    // environmentDomain_wall = "https://kw-api.staging.siba-service.jp/api" + VERSION;
    // environmentDomain_ist = "https://ist-signage.staging.siba-service.jp/api";
    // stubDomain = "http://localhost:8080";
    // postUri = "https://kw-api.staging.siba-service.jp/contribution_test/contribution.html";
}


const WALL_DOMAIN = environmentDomain_wall;
const IST_DOMAIN = environmentDomain_ist;

export const STUB_DOMAIN = stubDomain;

export const API_FETCH_TOPIC = WALL_DOMAIN + "/get-available-topics";
export const API_VOTE_LIKE =  WALL_DOMAIN + "/vote-topic";
export const API_FETCH_STATUS = IST_DOMAIN + "/get-available-objects-now";

export const POST_URI = postUri;
