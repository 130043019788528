/**
 * Created by sibaservice on 2017/05/15.
 */
import { createActions,createAction, handleActions, combineActions } from 'redux-actions'
import * as url from 'constants/api'

export function apiPayloadCreator(method,data){
    return {
        method: method,
        data: data
    }
}

export const Connection = createActions({
    TOPICS: {
        REQUEST: [
            (method,data) => ({method,data,api:url.API_FETCH_TOPIC}),
            () =>  ({fetch : true})
        ],
        RECEIVE : undefined
    },
    VOTE: {
        REQUEST: [
            (method,data) => ({method,data, api:url.API_VOTE_LIKE}),
            () => ({fetch: true})
        ],
        RECEIVE : undefined
    },
    STATUS: {
        REQUEST: [
            (method) => ({method, api:url.API_FETCH_STATUS}),
            () => ({fetch: true})
        ],
        RECEIVE: undefined
    }
});


export const Loading = createAction('LOADING');



export const TYPE_UPDATE_SCREEN_SIZE = "TYPE_UPDATE_SCREEN_SIZE";
export function resizeWindowSize(height,width) {
    return {
        type: TYPE_UPDATE_SCREEN_SIZE,
        screenHeight : height,
        screenWidth: width
    }
}
