import React, {Component} from 'react';
import {connect, store} from 'react-redux'
import * as Actions from '../actions/actions'
import cx from 'classnames';
import FavoriteIcon from 'material-ui/svg-icons/action/favorite';

const mapStateToProps = (state, props) => {
    return {
        screenSize: state.screenSize
    }
};

const mapDispatchToProps = dispatch => {
    return {
        voteTopics: (data) => {
            dispatch(Actions.Connection.vote.request("post", {"TOPIC_ID": data}))
        }
    }
};

class LikeButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            voteCount:props.voteCount,
            topicId: props.topicId
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.voteCount !== nextProps.voteCount || this.state.topicId !== nextProps.topicId) {
            this.state = {
                voteCount: nextProps.voteCount,
                topicId: nextProps.topicId
            }
        }
    }

    componentDidMount () {
        const elm = this.refs.button
        elm.addEventListener('animationend', this.animatingDone)
    }
    componentWillUnmount () {
        const elm = this.refs.button
        elm.removeEventListener('animationend', this.animatingDone)
    }


    handleLike = () => {
        this.props.voteTopics(this.state.topicId)
        this.setState({
            isButtonAnimating:true,
            voteCount: this.state.voteCount + 1
        })
    }


    animatingDone = () => {
        this.setState({
            isButtonAnimating:false
        })
    }

    render() {
        let buttonClassName = cx("like_button_icon",{rubberBand: this.state.isButtonAnimating});

        return (
            <div
                ref="button"
                className={buttonClassName} onClick={this.handleLike}>
                <div className="like_button_icon_left">
                    <div>
                        <FavoriteIcon
                            color="white"
                        />
                    </div>
                </div>
                <div className="like_button_icon_right">
                    <span>{this.state.voteCount}</span>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LikeButton)