/**
 * Created by sibaservice on 2017/05/11.
 */
import React, {Component} from 'react';
import {connect, store} from 'react-redux'
import ReactInterval from 'react-interval';
import * as Actions from '../actions/actions'
import moment from 'moment'
import "moment/locale/ja";
import log from '../utils/logger';
import CardComponent from './Card'
import ClassNames from 'classnames';
import DetailDialog from './DetailDialog';
import CircularProgress from 'material-ui/CircularProgress';
import * as Constants from '../constants/constants';
import NotFound from "./404";
import IntervalTimer from "./utils/IntervalTimer";
import TimeView from "./utils/TimeView";

const mapStateToProps = (state, props) => {
    log.debug(state.screenSize)
    return {
        screenSize: state.screenSize,
        topics: state.topics,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTopics: (data) => {
            let postData;
            log.debug(data)
            switch(data) {
                //fixme カテゴリIDはカテゴリ取得部分を書いておかないといけない。
                case "headline":
                    postData = {"CATEGORIES":[1,2,3,4,5,6],SORT_FOR_SIGNAGE: true};
                    break;
                case "user_news":
                    postData = {"CATEGORIES":[5],SORT_FOR_SIGNAGE: true};
                    break;
                case "university_news":
                    postData = {"CATEGORIES":[4],SORT_FOR_SIGNAGE: true};
                    break;
                case "twitter":
                    postData = {"CATEGORIES":[1],SORT_FOR_SIGNAGE: true};
                    break;
                case "facebook":
                    postData = {"CATEGORIES":[2],SORT_FOR_SIGNAGE: true};
                    break;
                case "youtube":
                    postData = {"CATEGORIES":[3],SORT_FOR_SIGNAGE: true};
                    break;
                default:
                    postData = {"CATEGORIES":[1,2,3,4,5,6],SORT_FOR_SIGNAGE: true};
            }
            dispatch(Actions.Connection.topics.request("post",postData));
        }
    }
};


class GridPattern1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            day: moment().format('YYYY年M月D日 ddd'),
            time: moment().format('H:mm'),
            open: false,
            photoIndex: 0,
            isOpen: false,
            isFetching: true,
            categoryId: props.categoryId,

            //currentのtopics
            currentTopicsArray: [],
            //topicsの全ての情報topicsArray
            allTopicsArray: [[]],
            //topicsのカウント（番目)
            currentWallCount: 0,
            //topicsの現在のwall枚数
            allWallCount: 1,

            //loop回数
            loop: 0,

            //以下intervalシステム用
            destroy: true,
            enabled: true,
            timeout: 30000,
            callback: this.changeWall
        }
        this.props.getTopics(props.categoryId);
    }


    componentWillReceiveProps(nextProps) {
        if(nextProps.categoryId !== this.state.categoryId){
            this.setState({
                categoryId: nextProps.categoryId,
                currentWallCount: 0,
                currentTopicsArray: [],
            })
            this.props.getTopics(nextProps.categoryId);
        }else{
            //todo 調整
            if (!nextProps.topics.meta.fetch) {
                if(!nextProps.topics.error) {
                    if (nextProps.topics.payload !== null) {
                        this.setState({
                            allTopicsArray: nextProps.topics.payload,
                            isFetching: nextProps.topics.meta.fetch
                        });
                        if (nextProps.topics.payload.length !== 0) {
                            this.generateTopics(nextProps.topics.payload);
                        }else{
                            this.setState({
                                destroy: true
                            })
                        }
                    }
                } else {
                    setTimeout(()=>this.props.getTopics(nextProps.categoryId), 10000);
                }
            } else {
                this.setState({
                    allTopicsArray: [],
                    isFetching: nextProps.topics.meta.fetch
                })
            }
        }
    }

    componentDidMount() {
    }

    changeWall = () => {
        //一時的にprogress
        this.setState({
            isFetching: true,
            count: this.state.count + 1,
        });

        const {currentWallCount,allWallCount} = this.state;
        if(currentWallCount === allWallCount -1){
            if(this.state.loop === 3) {
                if(this.state.categoryId === "headline") {
                    this.props.getTopics(this.state.categoryId);
                    return;
                }
                this.props.changeMenu(1);
                return;
            }
            this.setState({
                loop: this.state.loop+1,
                currentWallCount: 0,
                currentTopicsArray : this.state.allTopicsArray[0]
            });
        }else{
            this.setState({
                currentWallCount: currentWallCount + 1,
                currentTopicsArray : this.state.allTopicsArray[currentWallCount + 1]
            });
        }

        setTimeout(()=>{
            this.setState({
                isFetching: false
            })
        },1000)

    };

    onToggleDestroy = (bool) => {
        this.setState({destroy: bool,enabled:!bool});
    };

    handleOpen = (data) => {
        this.setState({
            open: true,
            data: data
        });
        this.onToggleDestroy(true);
    };

    handleClose = () => {
        this.setState({open: false});
        this.onToggleDestroy(false);
    };


    //デフォルトのtopicsArrayを利用してtopicsを生成
    generateTopics(topics){
        let topicsArray = [];
        log.debug(topics.length)
        let mainLoop = Math.ceil(topics.length/13);
        let amari = topics.length % 13;
        for (let i = 0; i < mainLoop; i++) {
            if(i !== mainLoop - 1){
                topicsArray.push(topics.slice( i*13, i*13+13))
            }else{
                if(amari !== 0){
                    topicsArray.push(topics.slice( i*13, i*13+amari))
                    if(i !== 0){
                        topicsArray.push(topics.slice((i-1)*13),(i-1)*13+(13-amari))
                    }
                }else{
                    topicsArray.push(topics.slice( i*13, i*13+13))
                }
            }
        }
        log.debug(topicsArray)
        this.setState({
            currentWallCount: 0,
            currentTopicsArray: topicsArray[0],
            allTopicsArray: topicsArray,
            allWallCount: mainLoop
        })
    }


    render() {
        log.debug("render");
        log.debug(this.state.currentWallCount + ":" + this.state.allWallCount);
        log.debug(this.state.currentTopicsArray);

        let cxArray = [];
        for (let i = 0; i < 13; i++) {
            cxArray.push(
                ClassNames({
                    "disable_card": i >= this.state.currentTopicsArray.length,
                    "card": true,
                    "card_w4h7": i === 0,
                    "card_w3h4": i === 1 || i === 2,
                    "card_w4h3": i === 3,
                    "card_w3h3": i >= 4 && i <= 7,
                    "card_w2h2": i >= 8 && i <= 12
                })
            )
            if( i >= this.state.currentTopicsArray.length){
                this.state.currentTopicsArray.push(Constants.DATA_PAYLOAD)
            }
        }

        let component;

        if (this.state.isFetching) {
            component = (
                <div className="App-left-component" id="grid-pattern-1" style={{position:"relative"}}>
                    <div className="sk-folding-cube">
                        <div className="sk-cube1 sk-cube" />
                        <div className="sk-cube2 sk-cube" />
                        <div className="sk-cube4 sk-cube" />
                        <div className="sk-cube3 sk-cube" />
                    </div>
                </div>
            )
        } else {
            if(this.state.allTopicsArray.length !== 0) {
                component = (
                    <div className="App-left-component" id="grid-pattern-1">
                        <div id="card_w4h7" className={cxArray[0]}>
                            <CardComponent card_id="card_w4h7"
                                           screenSize={this.props.screenSize} showDetail={this.handleOpen}
                                           data={this.state.currentTopicsArray[0]}
                            />
                        </div>
                        <div id="card_w4h3" className={cxArray[1]}>
                            <CardComponent card_id="card_w4h3"
                                           screenSize={this.props.screenSize}
                                           showDetail={(data) => this.handleOpen(data)}
                                           data={this.state.currentTopicsArray[1]}/>
                        </div>
                        <div id="card_w3h4_b" className={cxArray[2]}>
                            <CardComponent card_id="card_w3h4"
                                           screenSize={this.props.screenSize}
                                           showDetail={(data) => this.handleOpen(data)}
                                           data={this.state.currentTopicsArray[2]}/>
                        </div>
                        <div id="card_w3h4_a" className={cxArray[3]}>
                            <CardComponent card_id="card_w3h4"
                                           screenSize={this.props.screenSize}
                                           showDetail={(data) => this.handleOpen(data)}
                                           data={this.state.currentTopicsArray[3]}/>
                        </div>
                        <div id="card_w3h3_a" className={cxArray[4]}>
                            <CardComponent card_id="card_w3h3"
                                           screenSize={this.props.screenSize}
                                           showDetail={(data) => this.handleOpen(data)}
                                           data={this.state.currentTopicsArray[4]}/>
                        </div>
                        <div id="card_w3h3_b" className={cxArray[5]}>
                            <CardComponent card_id="card_w3h3"
                                           screenSize={this.props.screenSize}
                                           showDetail={(data) => this.handleOpen(data)}
                                           data={this.state.currentTopicsArray[5]}/>
                        </div>
                        <div id="card_w3h3_c" className={cxArray[6]}>
                            <CardComponent card_id="card_w3h3"
                                           screenSize={this.props.screenSize}
                                           showDetail={(data) => this.handleOpen(data)}
                                           data={this.state.currentTopicsArray[6]}/>
                        </div>
                        <div id="card_w3h3_d" className={cxArray[7]}>
                            <CardComponent card_id="card_w3h3"
                                           screenSize={this.props.screenSize}
                                           showDetail={(data) => this.handleOpen(data)}
                                           data={this.state.currentTopicsArray[7]}/>
                        </div>
                        <div id="card_w2h2_a" className={cxArray[8]}>
                            <CardComponent card_id="card_w2h2"
                                           screenSize={this.props.screenSize}
                                           showDetail={(data) => this.handleOpen(data)}
                                           data={this.state.currentTopicsArray[8]}/>
                        </div>
                        <div id="card_w2h2_b" className={cxArray[9]}>
                            <CardComponent card_id="card_w2h2"
                                           screenSize={this.props.screenSize}
                                           showDetail={(data) => this.handleOpen(data)}
                                           data={this.state.currentTopicsArray[9]}/>
                        </div>
                        <div id="card_w2h2_c" className={cxArray[10]}>
                            <CardComponent card_id="card_w2h2"
                                           screenSize={this.props.screenSize}
                                           showDetail={(data) => this.handleOpen(data)}
                                           data={this.state.currentTopicsArray[10]}/>
                        </div>
                        <div id="card_w2h2_d" className={cxArray[11]}>
                            <CardComponent card_id="card_w2h2"
                                           screenSize={this.props.screenSize}
                                           showDetail={(data) => this.handleOpen(data)}
                                           data={this.state.currentTopicsArray[11]}/>
                        </div>
                        <div id="card_w2h2_e" className={cxArray[12]}>
                            <CardComponent card_id="card_w2h2"
                                           screenSize={this.props.screenSize}
                                           showDetail={(data) => this.handleOpen(data)}
                                           data={this.state.currentTopicsArray[12]}/>
                        </div>
                        <div id={"card_time"}>
                            <TimeView />
                        </div>
                        {/*
                        <div className={"balloon"}>
                            <img className={"balloon-image"} src={process.env.PUBLIC_URL + '/svg/touch.svg'} />
                            <p className={"balloon-text"}>
                                記事をタッチして<br/>
                                詳細を閲覧しよう
                            </p>
                        </div>
                        */}
                        <IntervalTimer
                            enabled={this.state.enabled}
                            callback = {this.changeWall}
                            timeout={this.state.timeout}
                        />
                        <DetailDialog
                            open={this.state.open}
                            handleClose={this.handleClose}
                            data={this.state.data}/>
                    </div>
                );
            }else{
                component = (
                    <div className="App-left-component" id="not_found_404">
                        <NotFound/>
                    </div>
                );
            }
        }
        return component;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GridPattern1)

