/**
 * Created by sibaservice on 2017/05/11.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect, store} from 'react-redux'
import * as Actions from 'actions/actions'
import moment from 'moment'
import "moment/locale/ja";
import log from 'utils/logger';
import {Card} from 'material-ui/Card';
import ReactSVG from 'react-svg'
import ClassNames from 'classnames';
import "scss/map.scss"
import IconWc from 'images/icon_wc.png'
import IconWcMen from 'images/icon_wc_men.png'
import IconWc_women from 'images/icon_wc_women.png'
import IconEv from 'images/icon_ev.png'

const mapStateToProps = (state, props) => {
    log.debug(state.screenSize)
    return {
        screenSize: state.screenSize,
        topics: state.topics
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getStatus: () => {
            dispatch(Actions.Connection.status.request("get"))
        }
    }
};

class MapDemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: props.height,
            currentFloor: 1,
            floorPath: process.env.PUBLIC_URL + '/svg/dmo-map-02.svg',
            seats_status: props.seats_status,
            isFetching: props.isFetching
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            seats_status: nextProps.seats_status,
            isFetching: nextProps.isFetching
        })
    }

    handleSvgLoaded = () => {
        let reserveItems = document.getElementsByClassName("reserve_items");
        for (let i = 0; i < reserveItems.length; i++) {
            /* getElementsTagNameを使う*/
            reserveItems[i].classList.add("status-available")
        }



            log.debug(this.state.seats_status);

        this.state.seats_status.forEach((currentValue) => {
            let generateId = "reserve_" + currentValue.item_id;
            if(document.getElementById(generateId)){
                let list = document.getElementById(generateId);
                let elementList = list.children;

                let status_class;
                log.debug(currentValue.object_status);
                switch (currentValue.object_status) {
                    case "1":
                        status_class = "status-available";
                        break;
                    case "2":
                        status_class = "status-commingsoon";
                        break;
                    case "3":
                        status_class = "status-full";
                        break;
                    default:
                        status_class = "status-available";
                        break;
                }

                for (let i = 0; i < elementList.length; i++) {
                    elementList[i].classList.add(status_class)
                }
            }

            //FIXME SVG is not optimization!
            if(currentValue.item_id === "10001" || currentValue.item_id === "10002" ||currentValue.item_id === "10003"){


                let status_class;
                log.debug(currentValue.object_status);
                switch (currentValue.object_status) {
                    case "1":
                        status_class = "status-available";
                        break;
                    case "2":
                        status_class = "status-commingsoon";
                        break;
                    case "3":
                        status_class = "status-full";
                        break;
                    default:
                        status_class = "status-available";
                        break;
                }
                switch(currentValue.item_id){
                    case "10001":
                        if(document.getElementById("ld_01")){
                            let list = document.getElementById("ld_01");
                            let elementList = list.children;
                            for (let i = 0; i < elementList.length; i++) {
                                elementList[i].classList.add(status_class)
                            }
                        }
                        break;
                    case "10002":
                        if(document.getElementById("ld_02")){
                            let list = document.getElementById("ld_02");
                            let elementList = list.children;
                            for (let i = 0; i < elementList.length; i++) {
                                elementList[i].classList.add(status_class)
                            }
                        }
                        break;
                    case "10003":
                        if(document.getElementById("reserve_10003")){
                            let list = document.getElementById("reserve_10003");
                            list.classList.add(status_class);
                        }
                        break;
                }
            }

        })


    }

    handleChangeFloor(currentFloor){
        let floorPath;
        switch(currentFloor){
            case 0:
                floorPath = process.env.PUBLIC_URL + '/svg/dmo-map-01.svg';
                break;
            case 1:
                floorPath = process.env.PUBLIC_URL + '/svg/dmo-map-02.svg';
                break;
            default:
                floorPath = process.env.PUBLIC_URL + '/svg/dmo-map-02.svg';
                break;
        }

        this.setState({
            currentFloor: currentFloor,
            floorPath: floorPath
        })
        this.props.getStatus();
    }

    render() {

        let cxB1 = ClassNames({
            "floor_map_button": true,
            "select": this.state.currentFloor === 0
        });
        let cxF1 = ClassNames({
            "floor_map_button": true,
            "select": this.state.currentFloor === 1
        });
        let cxF2 = ClassNames({
            "floor_map_button": true,
            "select": this.state.currentFloor === 2
        });
        let cxF3 = ClassNames({
            "floor_map_button": true,
            "select": this.state.currentFloor === 3
        });
        let cxF4 = ClassNames({
            "floor_map_button": true,
            "select": this.state.currentFloor === 4
        });

        return (
            <Card className="card_outer">
                <div className="map_left_container">
                    <div className="floor_map_title">
                        <span className="commons">Knowledge Infinite Library</span><br/>フロアマップ
                    </div>
                    <div className="floor_map_button_container">
                        <div className={cxF1} onClick={() => this.handleChangeFloor(1)}>
                            <p>貸出ロッカー</p>
                        </div>
                        <div className={cxB1} onClick={() => this.handleChangeFloor(0)}>
                            <p>1F</p>
                        </div>
                    </div>
                    <div className="floor_map_marker_container">
                        <div className="center_container">

                            <div className="floor_map_marker">
                                <div className="floor_map_marker_icon">
                                    <img src={IconWcMen} />
                                </div>
                                <div className="floor_map_marker_title">
                                    <p>男性用トイレ</p>
                                </div>
                            </div>

                            <div className="floor_map_marker">
                                <div className="floor_map_marker_icon">
                                    <img src={IconWc_women} />
                                </div>
                                <div className="floor_map_marker_title">
                                    <p>女性用トイレ</p>
                                </div>
                            </div>
                            <div className="floor_map_marker">
                                <div className="floor_map_marker_icon">
                                    <img src={IconWc}  />
                                </div>
                                <div className="floor_map_marker_title">
                                    <p>多目的トイレ</p>
                                </div>
                            </div>

                            <div className="floor_map_marker">
                                <div className="floor_map_marker_icon">
                                    <img src={IconEv} />
                                </div>
                                <div className="floor_map_marker_title">
                                    <p>エレベーター</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(() => {
                    if (!this.state.isFetching) {
                        return (
                                <div className="map_right_container">
                                    <ReactSVG
                                        path={this.state.floorPath}
                                        callback={this.handleSvgLoaded}
                                        className="map_svg"
                                        style={{width: "auto", height: this.state.height}}
                                    />
                                </div>
                            )
                    }else{
                        return <div className="map_right_container" />
                    }
                })()}

            </Card>

        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapDemo)


Map.propTypes = {
    height: PropTypes.number
}