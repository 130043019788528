/**
 * Created by sibaservice on 2017/05/11.
 */
import * as loglevel from 'loglevel'

if (process.env.NODE_ENV === 'production') {
    loglevel.setLevel('error')
} else {
    loglevel.setLevel('trace')
}

export default loglevel