import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppDemo from 'components/demo/AppDemo'
import MapOnly from 'components/MapOnly'
import './scss/index.scss';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {compose, createStore, combineReducers, applyMiddleware} from 'redux'
import {Provider} from 'react-redux'
import createHistory from 'history/createBrowserHistory'
import * as actions from './actions/actions'
import * as reducers from './reducers/reducers'
import {Route} from 'react-router'
import {Switch} from 'react-router-dom'
import { useRouterHistory } from 'react-router';
import * as middlewares from './middleware/middleware'
import {ConnectedRouter, routerReducer, routerMiddleware, push} from 'react-router-redux'

import thunk from 'redux-thunk'


// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory({basename: "/"});


const store = createStore(
    combineReducers({
        screenSize: reducers.screenSize,
        topics: reducers.topics,
        loading: reducers.loading,
        vote: reducers.vote,
        status: reducers.status,
        routing: routerReducer,
    }),
    compose(
        applyMiddleware(thunk),
        applyMiddleware(routerMiddleware(history)),
        applyMiddleware(middlewares.connection)
    ),
)

ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route component={App}/>
                    {/*
                    //todo 後で戻す 環境に応じて変更すべき
                    <Route exact path="/locker" component={AppDemo} />
                    <Route exact path="/map" component={MapOnly} />
                    */}
                </Switch>
            </ConnectedRouter>
        </MuiThemeProvider>
    </Provider>,
    document.getElementById('root')
);
